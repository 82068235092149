import { storeToRefs } from 'pinia'
import { PAGE } from './router'
import { useGameStore } from '~/store/game'
import { LOBBY_GAME_URLS, PAGE_URLS, SPORTS_URLS } from '~~/config/page-url'
import { useGame } from '~/composables/game/useGame'

export const getHeaderMenu = () => {
  const { $pinia } = useNuxtApp()
  const storeGame = useGameStore($pinia)
  const { providerGames, providerCasino } = storeToRefs(storeGame)
  const { fetchCongGame } = useGame()
  if (Object.keys(providerGames.value).length === 0) {
    fetchCongGame()
  }
  const firstAlias = computed(() => {
    if (providerGames.value && Array.isArray(providerGames.value) && providerGames.value.length > 0) {
      return providerGames.value?.[0].alias
    }
    return 'table-game'
  })

  return {
    HEADER_MENU: [
      {
        id: 'sport',
        title: 'Thể Thao',
        icon: '/assets/images/components/desktop/icon-sport.svg',
        loginRequired: false,
        newTab: false,
        link: PAGE.SPORTS,
        route: PAGE.SPORTS,
        type: 'sport',
        subMenu: [
          {
            title: 'K-SPORTS',
            label: 'hot',
            image: '/assets/images/components/desktop/home/header/k-sport.png',
            loginRequired: false,
            newTab: false,
            url: SPORTS_URLS.K_SPORT
          },
          {
            title: 'C-SPORTS',
            label: '',
            image: '/assets/images/components/desktop/home/header/c-sport.png',
            loginRequired: false,
            newTab: false,
            url: SPORTS_URLS.C_SPORT
          },
          {
            title: 'BTI-SPORTS',
            label: '',
            image: '/assets/images/components/desktop/home/header/bti-sport.png',
            loginRequired: false,
            newTab: false,
            url: SPORTS_URLS.BTI_SPORT
          },
          {
            title: 'Thể thao ảo',
            image: '/assets/images/components/desktop/home/header/v-sport.png',
            loginRequired: false,
            newTab: false,
            url: SPORTS_URLS.VIRTUAL_SPORTS,
            isRequiredLogin: true
          },
          {
            title: 'E-Sports',
            label: '',
            image: '/assets/images/components/desktop/home/header/e-sport.png',
            loginRequired: false,
            newTab: false,
            url: SPORTS_URLS.E_SPORT,
            isRequiredLogin: true
          },
          {
            title: 'Volta',
            label: '',
            image: '/assets/images/components/desktop/home/header/volta-sport.png',
            loginRequired: false,
            newTab: false,
            url: SPORTS_URLS.VOLTA_SPORT,
            isRequiredLogin: true
          },
          {
            title: 'Schedule',
            label: '',
            image: '/assets/images/components/desktop/home/header/schedule.png',
            loginRequired: false,
            newTab: false,
            url: PAGE_URLS.SCHEDULES
          }
        ]
      },
      {
        id: 'live-casino',
        title: 'Live Casino',
        icon: '/assets/images/components/desktop/icon-live-casino.svg',
        loginRequired: false,
        newTab: false,
        link: PAGE.CASINO,
        route: PAGE.CASINO,
        type: 'casino'
      },
      {
        id: 'games',
        title: 'Cổng Game',
        icon: '/assets/images/components/desktop/icon-games.svg',
        loginRequired: true,
        newTab: true,
        link: PAGE.GAME,
        route: `${PAGE.GAME}/${firstAlias.value}`,
        type: 'game'
      },
      {
        id: 'games-da-ga',
        title: 'Đá gà',
        icon: '/assets/images/components/desktop/icon-game-da-ga.svg',
        loginRequired: true,
        newTab: true,
        link: `${PAGE.GAME}/da-ga`,
        route: `${PAGE.GAME}/da-ga`,
        type: 'game'
      },
      {
        id: 'promotion',
        title: 'Khuyến Mãi',
        icon: '/assets/images/components/desktop/icon-promotion.svg',
        loginRequired: false,
        newTab: false,
        type: 'lode',
        link: PAGE.PROMOTION,
        route: PAGE.PROMOTION
      }
    ]
  }
}

export const HEADER_BACKGROUND_GAME = {
  'table-game': 'linear-gradient(16deg, #FFF 28.84%, #A6DE62 192.55%)',
  'no-hu': 'linear-gradient(27deg, #FFF 35.93%, #EAAB4D 204.78%)',
  'game-bai': 'linear-gradient(27deg, #FFF 35.93%, #F2603E 204.78%)',
  slots: 'linear-gradient(27deg, #FFF 35.93%, #FFC2D1 113.81%)',
  'quay-so': 'linear-gradient(27deg, #FFF 35.95%, #DFE876 173.41%)',
  'lo-de': 'linear-gradient(27deg, #FFF 35.93%, #BA7FFF 204.78%)',
  'ban-ca': 'linear-gradient(27deg, #FFF 35.93%, #75C1F4 204.78%)',
  'game-nhanh': 'linear-gradient(16deg, #FFF 28.84%, #A6DE62 192.55%)'
}

export const HEADER_BACKGROUND_CASINO = {
  'tai-xiu': 'linear-gradient(180deg, #FFD4DA 0%, #FFF 100%)',
  'xoc-dia': 'linear-gradient(0deg, #FFF 0%, #A8C0EF 100%)',
  'bau-cua': 'linear-gradient(180deg, #FFD4DA 0%, #FFF 100%)',
  baccarat: 'linear-gradient(180deg, #FFE3BA 0%, #FFF 100%)',
  blackjack: 'linear-gradient(0deg, #FFF 0%, #DDF8FF 90.59%)',
  roulette: 'linear-gradient(0deg, #FFF 0%, #B8F8B7 99.69%)',
  poker: 'linear-gradient(180deg, #0C3E9D 0%, #D6BBE5 0.01%, #FFF 100%)',
  sicbo: 'linear-gradient(180deg, #FFE3BA 0%, #FFF 100%)',
  'tv-show': 'linear-gradient(0deg, #FFF 0%, #FFD9DE 100%)'
}
