<template>
  <div class="section-lottery">
    <div class="section-lottery__header">
      <h3 class="title">Xổ số 24H</h3>
    </div>
    <div class="section-lottery__list">
      <div v-for="(item, index) in HOME_LOTTERY_MB" :key="index">
        <LotteryItem :item="item" />
      </div>
    </div>
  </div>
</template>

<script setup>
import LotteryItem from './lottery-item.vue'
import { HOME_LOTTERY_MB } from '~/resources/home'
import useLotteryCountdown from '~/composables/useLotteryCountdown'

const { startCountdown } = useLotteryCountdown()
</script>

<style lang="scss" scoped src="public/assets/scss/components/mobile/pages/home/lottery/index.scss"></style>
