<template>
  <client-only>
    <div class="error-page">
      <div class="error-page__content">
        <CommonBaseImg src="assets/images/components/desktop/pages/404/icon-404-mb.svg" class="icon-404" alt="icon-404" />
        <span class="error-title">Không tìm thấy trang!</span>
        <span class="error-description">Có thể bạn đã nhập sai địa chỉ hoặc trang này đã được chuyển sang vị trí mới.</span>
        <BaseButton class="base-button--btn-primary" @click="goHome">trang chủ</BaseButton>
      </div>
    </div>
  </client-only>
</template>

<script setup lang="ts">
import { onMounted, onBeforeUnmount } from 'vue'
import { useRouter } from 'vue-router'
import BaseButton from '~/components/common/base-button.vue'
import CommonBaseImg from '~/components/common/base-img.vue'

const router = useRouter()
const goHome = () => {
  router.push('/')
}

const toggleBodyClass = (className, add) => {
  document.body.classList[add ? 'add' : 'remove'](className)
}

onMounted(() => toggleBodyClass('fix-screen', true))
onBeforeUnmount(() => toggleBodyClass('fix-screen', false))
</script>

<style scoped lang="scss" src="~/public/assets/scss/page/error-mb.scss"></style>
