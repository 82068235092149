import { storeToRefs } from 'pinia'
import { GAME_PAGE_URL } from '~/constants/lobby'
import {
  IMenuHeader,
  IMenuUserDesktop,
  IMenuWithDraw,
  IMenuDepositBank,
  IMenuDepositDesktop,
  IMenuDeposit,
  IMenuMobile,
  IMenuBottom
} from '~/types/menu.type'
import { ACCOUNT_URLS, LOBBY_GAME_URLS, PAGE_URLS } from '~/config/page-url'
import { LABEL } from '~/constants/account/history/transactions'
import { LOBBY_CASINO_URLS } from '~~/config/page-url'
import { useAppStore } from '~~/store/app'
import { IContact } from '~~/types/seo'
import { useGameStore } from '~/store/game'
import { useGame } from '~/composables/game/useGame'
// const { $config } = useNuxtApp()

export const PATH_ICON = '/assets/images/components/desktop/menu/'
export const PATH_ICON_MENU_BOTTOM = '/assets/images/icon/'
export const PATH_HEADER_MENU = 'assets/images/components/desktop/home/header/'

export const PATH_ICON_ACCOUNT = '/assets/images/components/desktop/menu/account/'

export const LOGO_SITE_IMAGE = '/assets/brand/logo.webp'

export const IMAGE_PATH_ACCOUNT = '/assets/images/components/desktop/pages/account/layout/'
export const IMAGE_PATH_DEPOSIT = '/assets/images/components/desktop/pages/account/deposit/'
export const TRANSACTION_P2P_TYPE = {
  BUY: 'buy',
  SELL: 'sell'
}
export const PATH_ICON_ACC_LIGHT = '/assets/images/components/mobile/light/account/'

export const MENUS: IMenuHeader[] = [
  {
    type: 'sports',
    name: 'sports',
    tab: 'tab-sport',
    title: 'Thể thao',
    activeUrl: '/sports'
  },
  {
    type: 'livecasino',
    name: 'casino',
    title: 'Live Casino',
    tab: 'tab-casino',
    activeUrl: GAME_PAGE_URL.all,
    subMenu: []
  },
  {
    type: 'games',
    name: 'game-type',
    title: 'Cổng Games',
    tab: 'tab-games',
    activeUrl: LOBBY_GAME_URLS.ALL,
    subMenu: []
  },
  {
    name: 'line',
    title: '',
    tab: '',
    activeUrl: '',
    subMenu: undefined
  },
  {
    name: 'khuyen-mai',
    title: 'Khuyến mãi',
    tab: 'tab-promotion',
    activeUrl: '/khuyen-mai',
    subMenu: []
  }
]

export const USER_MENU: IMenuUserDesktop[] = [
  {
    id: 1,
    name: 'Tài khoản',
    url: ACCOUNT_URLS.PROFILE,
    icon: PATH_ICON_ACCOUNT + 'help-register.svg',
    activeUrl: ACCOUNT_URLS.PROFILE,
    activeUrlRelative: ACCOUNT_URLS.PROFILE,
    hiddenInTab: false,
    isLogout: false,
    img: 'icon-profile',
    imgLight: 'icon-profile-light'
  },
  {
    id: 2,
    name: 'Nạp tiền',
    url: ACCOUNT_URLS.DEPOSIT_DA,
    icon: PATH_ICON_ACCOUNT + 'icon-deposit.svg',
    activeUrl: ACCOUNT_URLS.DEPOSIT_DA,
    activeUrlRelative: ACCOUNT_URLS.DEPOSIT,
    hiddenInTab: false,
    isLogout: false,
    img: 'icon-deposit2',
    imgLight: 'icon-deposit-light'
  },
  {
    id: 3,
    name: 'Rút tiền',
    url: ACCOUNT_URLS.WITHDRAW_BANK,
    icon: PATH_ICON_ACCOUNT + 'icon-withdraw.svg',
    activeUrl: ACCOUNT_URLS.WITHDRAW_BANK,
    activeUrlRelative: ACCOUNT_URLS.WITHDRAW,
    hiddenInTab: false,
    isLogout: false,
    img: 'icon-withdraw2',
    imgLight: 'icon-withdraw-light'
  },
  {
    id: 4,
    name: 'MUA BÁN P2P',
    url: ACCOUNT_URLS.P2P,
    icon: PATH_ICON_ACCOUNT + 'icon-p2pc.svg',
    activeUrl: ACCOUNT_URLS.P2P,
    activeUrlRelative: ACCOUNT_URLS.P2P,
    hiddenInTab: false,
    isLogout: false,
    img: 'icon-p2pc',
    imgLight: 'icon-p2p-light'
  },
  {
    id: 5,
    name: 'Lịch sử',
    url: ACCOUNT_URLS.TRANSACTION_HISTORY,
    icon: PATH_ICON_ACCOUNT + 'icon-history.svg',
    activeUrl: ACCOUNT_URLS.TRANSACTION_HISTORY,
    activeUrlRelative: ACCOUNT_URLS.BET_HISTORY,
    hiddenInTab: false,
    isLogout: false,
    img: 'icon-history',
    imgLight: 'icon-history-light'
  },
  {
    id: 6,
    name: 'Tiền thưởng',
    url: ACCOUNT_URLS.BONUS,
    icon: PATH_ICON_ACCOUNT + 'icon-bonus.svg',
    activeUrl: ACCOUNT_URLS.BONUS,
    activeUrlRelative: ACCOUNT_URLS.BONUS,
    hiddenInTab: false,
    isLogout: false,
    img: 'icon-icon-gift',
    imgLight: 'icon-bonus-light'
  },
  {
    id: 7,
    name: 'Ngân hàng',
    url: ACCOUNT_URLS.BANK,
    icon: PATH_ICON_ACCOUNT + 'icon-bank.svg',
    activeUrl: ACCOUNT_URLS.BANK,
    activeUrlRelative: ACCOUNT_URLS.BANK,
    hiddenInTab: false,
    isLogout: false,
    img: 'icon-bank',
    imgLight: 'icon-bank-light'
  },
  {
    id: 8,
    name: 'Đăng xuất',
    url: '',
    icon: PATH_ICON_ACCOUNT + 'icon-logout.svg',
    activeUrl: '',
    activeUrlRelative: '',
    hiddenInTab: false,
    isLogout: true
  }
]
export const USER_MENU_HEADER: IMenuUserDesktop[] = [
  {
    id: 1,
    name: 'Quản lí tài khoản',
    url: ACCOUNT_URLS.PROFILE,
    icon: {
      active: PATH_ICON_ACCOUNT + 'active-help-register.svg',
      inactive: PATH_ICON_ACCOUNT + 'help-register.svg'
    },
    activeUrl: ACCOUNT_URLS.PROFILE,
    activeUrlRelative: ACCOUNT_URLS.PROFILE,
    hiddenInTab: false,
    isLogout: false
  },
  {
    id: 2,
    name: 'Nạp tiền',
    url: ACCOUNT_URLS.DEPOSIT_DA,
    icon: {
      active: PATH_ICON_ACCOUNT + 'active-icon-header-deposit.svg',
      inactive: PATH_ICON_ACCOUNT + 'icon-header-deposit.svg'
    },
    activeUrl: ACCOUNT_URLS.DEPOSIT_DA,
    activeUrlRelative: ACCOUNT_URLS.DEPOSIT_DA,
    hiddenInTab: false,
    isLogout: false
  },
  {
    id: 3,
    name: 'Rút tiền',
    url: ACCOUNT_URLS.WITHDRAW_BANK,
    icon: {
      active: PATH_ICON_ACCOUNT + 'active-icon-header-withdraw.svg',
      inactive: PATH_ICON_ACCOUNT + 'icon-header-withdraw.svg'
    },
    activeUrl: ACCOUNT_URLS.WITHDRAW_BANK,
    activeUrlRelative: ACCOUNT_URLS.WITHDRAW,
    hiddenInTab: false,
    isLogout: false
  },
  {
    id: 2,
    name: 'Giao dịch P2P',
    url: ACCOUNT_URLS.P2P,
    icon: {
      active: PATH_ICON_ACCOUNT + 'active-icon-p2pc.svg',
      inactive: PATH_ICON_ACCOUNT + 'icon-p2pc.svg'
    },
    activeUrl: ACCOUNT_URLS.P2P,
    activeUrlRelative: ACCOUNT_URLS.P2P,
    hiddenInTab: false,
    isLogout: false
  },
  {
    id: 3,
    name: 'Lịch sử',
    url: ACCOUNT_URLS.TRANSACTION_HISTORY,
    icon: {
      active: PATH_ICON_ACCOUNT + 'active-icon-history.svg',
      inactive: PATH_ICON_ACCOUNT + 'icon-history.svg'
    },
    activeUrl: ACCOUNT_URLS.TRANSACTION_HISTORY,
    activeUrlRelative: ACCOUNT_URLS.BET_HISTORY,
    hiddenInTab: false,
    isLogout: false
  },
  {
    id: 4,
    name: 'Tiền thưởng',
    url: ACCOUNT_URLS.BONUS,
    icon: {
      active: PATH_ICON_ACCOUNT + 'active-icon-bonus.svg',
      inactive: PATH_ICON_ACCOUNT + 'icon-bonus.svg'
    },
    activeUrl: ACCOUNT_URLS.BONUS,
    activeUrlRelative: ACCOUNT_URLS.BONUS,
    hiddenInTab: false,
    isLogout: false
  },
  {
    id: 5,
    name: 'Đăng xuất',
    url: '',
    icon: {
      active: PATH_ICON_ACCOUNT + 'active-icon-logout.svg',
      inactive: PATH_ICON_ACCOUNT + 'icon-logout.svg'
    },
    activeUrl: '',
    activeUrlRelative: '',
    hiddenInTab: false,
    isLogout: true
  }
]

export const MENU_WITHDRAW: IMenuWithDraw[] = [
  {
    name: 'Ngân hàng',
    desc: 'Duyệt rút nhanh chóng',
    queryValue: 'bank',
    fullPath: ACCOUNT_URLS.WITHDRAW_BANK,
    icon: 'icon-bank',
    key: 'bank',
    iconMB: 'icon-bank-bold',
    isHot: false,
    isNew: false,
    isMaintain: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/withdraw/bank/index.vue')),
    link: ACCOUNT_URLS.WITHDRAW_BANK
  },
  {
    name: 'Giao dịch P2P',
    desc: 'Nhanh chóng, tiện lợi',
    queryValue: 'sell',
    fullPath: `${ACCOUNT_URLS.TRANSACTION_P2P}?type=${TRANSACTION_P2P_TYPE.SELL}`,
    icon: 'icon-p2pc',
    iconMB: 'icon-p2p-bold',
    isHot: false,
    isNew: false,
    isMaintain: false,
    component: null,
    link: `${ACCOUNT_URLS.TRANSACTION_P2P}?type=${TRANSACTION_P2P_TYPE.SELL}`
  },
  {
    name: 'Tiền ảo',
    desc: 'Duyệt tự động',
    queryValue: 'crypto',
    fullPath: ACCOUNT_URLS.WITHDRAW_CRYPTO,
    icon: 'icon-crypto',
    key: 'crypto',
    iconMB: 'icon-crypto-menu',
    isHot: false,
    isNew: false,
    isMaintain: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/withdraw/crypto/index.vue')),
    link: ACCOUNT_URLS.WITHDRAW_CRYPTO
  },
  {
    name: 'Thẻ cào',
    desc: 'Hỗ trợ 5 thẻ / lần',
    queryValue: 'card',
    fullPath: ACCOUNT_URLS.WITHDRAW_CARD,
    icon: 'icon-card',
    key: 'cardList',
    iconMB: 'icon-card',
    isHot: false,
    isNew: false,
    isMaintain: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/withdraw/card/index.vue')),
    link: ACCOUNT_URLS.WITHDRAW_CARD
  }
]

export const MENU_WITHDRAW_DESKTOP: IMenuWithDraw[] = [
  {
    name: 'Ngân hàng',
    desc: 'Duyệt rút nhanh chóng',
    queryValue: 'bank',
    fullPath: ACCOUNT_URLS.WITHDRAW_BANK,
    key: 'bank',
    icon: 'icon-bank',
    iconMB: 'icon-bank',
    isHot: false,
    isNew: false,
    isMaintain: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/withdraw/bank/index.vue')),
    link: ACCOUNT_URLS.WITHDRAW_BANK
  },
  {
    name: 'Tiền ảo',
    desc: 'Duyệt tự động',
    queryValue: 'crypto',
    fullPath: ACCOUNT_URLS.WITHDRAW_CRYPTO,
    key: 'crypto',
    icon: 'icon-crypto',
    iconMB: 'icon-crypto',
    isHot: false,
    isNew: false,
    isMaintain: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/withdraw/crypto/index.vue')),
    link: ACCOUNT_URLS.WITHDRAW_CRYPTO
  },
  {
    name: 'Thẻ cào',
    desc: 'Hỗ trợ 5 thẻ / lần',
    queryValue: 'card',
    fullPath: ACCOUNT_URLS.WITHDRAW_CARD,
    key: 'cardList',
    icon: 'icon-card',
    iconMB: 'icon-card',
    isHot: false,
    isNew: false,
    isMaintain: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/withdraw/card/index.vue')),
    link: ACCOUNT_URLS.WITHDRAW_CARD
  }
]

export const MENU_DEPOSIT: IMenuDeposit[] = [
  {
    name: 'CodePay',
    nameMB: 'Codepay',
    queryValue: 'codepay',
    fullPath: ACCOUNT_URLS.DEPOSIT_DA,
    key: 'nicepayBanks',
    desc: 'Khuyến mãi hấp dẫn',
    icon: 'icon-codepay',
    isHot: false,
    isNew: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/bank/index.vue')),
    componentMobile: defineAsyncComponent(() => import('~/components/mobile/pages/account/deposit/bank/index.vue')),
    link: ACCOUNT_URLS.DEPOSIT_DA,
    isPromotion08: false,
    isMaintain: false
  },
  {
    name: 'CodePay2',
    nameMB: 'Codepay2',
    queryValue: 'codepay2',
    fullPath: ACCOUNT_URLS.DEPOSIT_CODEPAY2,
    key: 'providerGoPay',
    desc: 'Quét mã thanh toán tự động',
    icon: 'icon-codepay2',
    isHot: false,
    isNew: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/bank/index.vue')),
    componentMobile: defineAsyncComponent(() => import('~/components/mobile/pages/account/deposit/bank/index.vue')),
    link: ACCOUNT_URLS.DEPOSIT_CODEPAY2,
    isPromotion08: false,
    isMaintain: false
  },
  {
    name: 'Nạp P2P',
    nameMB: 'Giao dịch P2P',
    desc: 'Nhanh chóng, tiện lợi',
    queryValue: 'buy',
    fullPath: `${ACCOUNT_URLS.TRANSACTION_P2P}?type=${TRANSACTION_P2P_TYPE.BUY}`,
    key: 'buy',
    icon: 'icon-p2p-bold',
    isHot: false,
    isNew: false,
    component: null,
    componentMobile: null,
    link: `${ACCOUNT_URLS.TRANSACTION_P2P}?type=${TRANSACTION_P2P_TYPE.BUY}`,
    isPromotion08: false,
    isMaintain: false
  },
  {
    name: 'Tiền ảo',
    nameMB: 'Tiền ảo',
    desc: 'Nạp USDT tức thì',
    key: 'crypto_v2',
    queryValue: 'crypto',
    fullPath: ACCOUNT_URLS.DEPOSIT_CRYPTO,
    icon: 'icon-crypto-menu',
    isHot: false,
    isNew: false,
    component: null,
    componentMobile: null,
    link: ACCOUNT_URLS.DEPOSIT_CRYPTO,
    isPromotion08: true,
    isMaintain: false
  },
  {
    name: 'Ví điện tử',
    nameMB: 'Ví điện tử',
    queryValue: 'momo',
    fullPath: ACCOUNT_URLS.DEPOSIT_MOMO,
    key: 'momos',
    desc: 'Momo, Viettel Money',
    icon: 'icon-wallets',
    isHot: false,
    isNew: false,
    component: null,
    componentMobile: null,
    link: ACCOUNT_URLS.DEPOSIT_MOMO,
    isPromotion08: false,
    isMaintain: false
  },
  {
    name: 'Thẻ cào',
    nameMB: 'Thẻ cào',
    desc: 'Nhiều nhà mạng hỗ trợ',
    queryValue: 'card',
    fullPath: ACCOUNT_URLS.DEPOSIT_CARD,
    key: 'cardList',
    icon: 'icon-card',
    isHot: false,
    isNew: false,
    component: null,
    componentMobile: null,
    link: ACCOUNT_URLS.DEPOSIT_CARD,
    isPromotion08: false,
    isMaintain: false
  }
]

export const MENU_DEPOSIT_DESKTOP: IMenuDepositDesktop[] = [
  {
    name: 'Ngân Hàng',
    queryValue: 'codepay',
    fullPath: [ACCOUNT_URLS.DEPOSIT_BANK, ACCOUNT_URLS.DEPOSIT_DA, ACCOUNT_URLS.DEPOSIT_CODEPAY2],
    key: 'nicepayBanks',
    desc: 'Quét mã thanh toán tự động',
    icon: 'icon-bank',
    isHot: false,
    isNew: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/bank/index.vue')),
    component2: null,
    componentMobile: null,
    componentMobile2: null,
    link: ACCOUNT_URLS.DEPOSIT_DA,
    isPromotion08: false,
    isMaintain: false
  },
  {
    name: 'Tiền Ảo',
    desc: 'Tặng 0.8%',
    key: 'crypto_v2',
    queryValue: 'crypto',
    fullPath: [ACCOUNT_URLS.DEPOSIT_CRYPTO],
    icon: 'icon-crypto',
    isHot: false,
    isNew: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/crypto/index.vue')),
    componentMobile: null,
    component2: null,
    componentMobile2: null,
    link: ACCOUNT_URLS.DEPOSIT_CRYPTO,
    isPromotion08: false,
    isMaintain: false
  },
  {
    name: 'Ví Điện Tử',
    queryValue: 'e-wallet',
    fullPath: [ACCOUNT_URLS.DEPOSIT_MOMO, ACCOUNT_URLS.DEPOSIT_VIETTEL_PAY, ACCOUNT_URLS.DEPOSIT_ZALO_PAY],
    key: 'e-wallet',
    desc: 'Siêu tiện lợi, nhanh chóng',
    icon: 'icon-wallets',
    isHot: false,
    isNew: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/e-wallet/index.vue')),
    componentMobile: null,
    component2: null,
    componentMobile2: null,
    link: ACCOUNT_URLS.DEPOSIT_MOMO,
    isPromotion08: false,
    isMaintain: false
  },
  {
    name: 'Thẻ Cào',
    desc: 'Mệnh giá đa dạng',
    queryValue: 'card',
    fullPath: [ACCOUNT_URLS.DEPOSIT_CARD],
    key: 'cardList',
    icon: 'icon-card',
    isHot: false,
    isNew: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/card/index.vue')),
    componentMobile: null,
    component2: null,
    componentMobile2: null,
    link: ACCOUNT_URLS.DEPOSIT_CARD,
    isPromotion08: false,
    isMaintain: false
  }
]
export const MENU_DEPOSIT_BANK: IMenuDepositBank[] = [
  {
    name: 'CodePay',
    queryValue: 'codepay',
    fullPath: ACCOUNT_URLS.DEPOSIT_DA,
    pathActive: ACCOUNT_URLS.DEPOSIT_BANK,
    key: 'nicepayBanks',
    icon: 'icon-codepay',
    iconActive: 'icon-codepay',
    isHot: true,
    isNew: false,
    link: ACCOUNT_URLS.DEPOSIT_DA,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/bank/index.vue')),
    componentMobile: null,
    isMaintain: false
  },
  {
    name: 'CodePay2',
    queryValue: 'codepay2',
    fullPath: ACCOUNT_URLS.DEPOSIT_CODEPAY2,
    key: 'providerGoPay',
    icon: 'icon-codepay2',
    iconActive: 'icon-codepay2',
    isHot: false,
    isNew: false,
    link: ACCOUNT_URLS.DEPOSIT_CODEPAY2,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/bank/index.vue')),
    componentMobile: null,
    isMaintain: false
  }
]

export const MENU_HISTORY = [
  {
    name: LABEL.HISTORY_TRANSACTIONS,
    link: ACCOUNT_URLS.TRANSACTION_HISTORY,
    icon: 'icon-history-transaction',
    isMaintain: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/history/transactions/index.vue')),
    componentMobile: defineAsyncComponent(
      () => import('~/components/mobile/pages/account/history/transactions/index.vue')
    )
  },
  {
    name: LABEL.HISTORY_BETS,
    link: ACCOUNT_URLS.BET_HISTORY,
    icon: 'icon-history-bets',
    isMaintain: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/history/bets/index.vue')),
    componentMobile: defineAsyncComponent(() => import('~/components/mobile/pages/account/history/bet/index.vue'))
  }
]

export const NOTE = [
  'Nạp / Rút bằng tài khoản chính chủ.',
  'Hỗ trợ chuyển tiền liên ngân hàng.',
  'Lưu lại biên lai để đối chiếu khi cần.'
]

export const USER_MENU_MOBILE: IMenuMobile[] = [
  {
    name: 'Quản lý tài khoản',
    url: ACCOUNT_URLS.PROFILE,
    icon: 'icon-profile-light',
    activeUrl: [ACCOUNT_URLS.PROFILE],
    isLiveChat: false
  },
  {
    name: 'Quản lý ngân hàng',
    url: ACCOUNT_URLS.BANK,
    icon: 'icon-bank-light',
    activeUrl: [ACCOUNT_URLS.PROFILE],
    isLiveChat: false
  },
  {
    name: 'Giao dịch P2P',
    url: ACCOUNT_URLS.P2P,
    icon: 'icon-p2p-light',
    activeUrl: [ACCOUNT_URLS.P2P],
    isLiveChat: false
  },
  {
    name: 'Lịch sử giao dịch',
    url: `${ACCOUNT_URLS.TRANSACTION_HISTORY}`,
    icon: 'icon-history-transaction',
    activeUrl: [ACCOUNT_URLS.TRANSACTION_HISTORY],
    isLiveChat: false
  },
  {
    name: 'Lịch sử cá cược',
    url: `${ACCOUNT_URLS.BET_HISTORY}`,
    icon: 'icon-history-bets',
    activeUrl: [ACCOUNT_URLS.BET_HISTORY],
    isLiveChat: false
  },
  {
    name: 'Tiền thưởng',
    url: `${ACCOUNT_URLS.BONUS}`,
    icon: 'icon-bonus-light',
    activeUrl: [ACCOUNT_URLS.BONUS],
    isLiveChat: false
  },
  {
    name: 'Live chat 24/7',
    url: '',
    icon: 'icon-livechat',
    activeUrl: [],
    isLiveChat: true
  }
]

export const getBottomMenu = () => {
  const { $pinia } = useNuxtApp()
  const storeGame = useGameStore($pinia)
  const { providerGames, providerCasino } = storeToRefs(storeGame)
  const { fetchCongGame } = useGame()
  if (Object.keys(providerGames.value).length === 0) {
    fetchCongGame()
  }
  const firstAlias = computed(() => {
    if (providerGames.value && Array.isArray(providerGames.value) && providerGames.value.length > 0) {
      return providerGames.value?.[0].alias
    }
    return 'table-game'
  })

  return {
    BOTTOM_MENU: [
      {
        key: 'promotion',
        name: 'Khuyến mãi',
        url: PAGE_URLS.PROMOTION,
        activeUrl: PAGE_URLS.PROMOTION,
        icon: PATH_ICON_MENU_BOTTOM + 'gift-mb.svg'
      },
      {
        key: 'home',
        name: 'Trang Chủ',
        url: PAGE_URLS.HOME,
        activeUrl: PAGE_URLS.HOME,
        icon: PATH_ICON_MENU_BOTTOM + 'home-mb.svg'
      },
      {
        key: 'sport',
        name: 'Thể Thao',
        url: PAGE_URLS.SPORT,
        activeUrl: PAGE_URLS.SPORT,
        icon: PATH_ICON_MENU_BOTTOM + 'ball-mb.svg'
      },
      {
        key: 'games',
        name: 'Cổng Game',
        url: `${LOBBY_GAME_URLS.GAME}/${firstAlias.value}`,
        activeUrl: LOBBY_GAME_URLS.ALL,
        icon: PATH_ICON_MENU_BOTTOM + 'cong-game-mb.svg'
      },
      {
        key: 'lottery',
        name: 'Quay Số',
        url: LOBBY_GAME_URLS.QUAY_SO,
        activeUrl: LOBBY_GAME_URLS.QUAY_SO,
        icon: PATH_ICON_MENU_BOTTOM + 'quay-so-mb.svg'
      },
      {
        key: 'live-casino',
        name: 'Live Casino',
        url: LOBBY_CASINO_URLS.ROOT,
        activeUrl: LOBBY_CASINO_URLS.ROOT,
        icon: PATH_ICON_MENU_BOTTOM + 'live-casino-mb.svg'
      }
    ]
  }
}

export const supportMenu = () => {
  const { $pinia } = useNuxtApp()
  const store = useAppStore($pinia)
  const { contact } = storeToRefs(store)
  const result = contact?.value?.reduce((acc, item: IContact) => {
    acc[item.name.toLowerCase()] = { url: item.url, display_name: item.display_name }
    return acc
  }, {} as Record<string, { url: string; display_name: string }>)

  return [
    {
      name: result?.cskh?.display_name,
      url: result?.cskh?.url,
      activeUrl: [],
      iconMB: PATH_ICON_MENU_BOTTOM + 'telegram.svg'
    },
    {
      name: result?.livechat?.display_name,
      url: result?.livechat?.url,
      activeUrl: [],
      isLiveChat: true,
      iconMB: PATH_ICON_MENU_BOTTOM + 'chat.svg'
    },
    {
      name: result?.hotline?.display_name,
      url: result?.hotline?.url,
      activeUrl: [],
      iconMB: PATH_ICON_MENU_BOTTOM + 'email.svg'
    },
    {
      name: result?.telegram?.display_name,
      url: result?.telegram?.url,
      activeUrl: [],
      iconMB: PATH_ICON_MENU_BOTTOM + 'people.svg'
    }
  ]
}
