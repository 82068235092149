<template>
  <Banner />
  <div class="home">
    <GameCenter />
    <Stream />
    <section class="section-list">
      <Sport />
      <Lottery />
      <LiveCasino />
    </section>
    <HomePromotion />
  </div>
</template>

<script setup lang="ts">
import Sport from './sport/index.vue'
import Lottery from './lottery/index.vue'
import LiveCasino from './live-casino/index.vue'
import HomePromotion from './promotion/index.vue'
import Banner from '~/components/desktop/pages/home/banner/index.vue'
import GameCenter from '~/components/desktop/pages/home/game-center/index.vue'
import Stream from '~/components/desktop/pages/home/stream/index.vue'

const router = useRouter()
const route = useRoute()

onMounted(() => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
})
const clearQuery = () => {
  const openUrl = route.query.openUrl as string
  if (openUrl) {
    router.replace({ path: route.path, query: {} })
  }
}
onMounted(() => {
  clearQuery()
})
</script>

<style lang="scss" scoped src="public/assets/scss/components/desktop/pages/home/index.scss" />
