<template>
  <div class="base-selector" :class="[className]">
    <div
      :class="{
        focused: currentValue,
        'input-error': $v.currentValue.$error,
        disabled: disabled,
        show: isShowOptions
      }"
      class="base-selector__wrap"
      tabindex="0"
      @focusout="hideOption"
      @click="toggleOptions"
    >
      <span class="base-selector__down">
        <BaseImg :src="srcIconSelectDown" alt="arrow" />
      </span>
      <div class="base-selector__options-wrapper">
        <ul v-if="isShowOptions" class="base-selector__options">
          <li
            v-for="item in items"
            :key="getValue(item)"
            :class="{ active: currentValue === getValue(item) }"
            @click.stop="chooseOption(item)"
          >
            <BaseImg
              v-if="item.icon || item.iconMB"
              :src="getIconSrc(item)"
              :alt="item?.name"
            />
            <p>{{ getText(item) }}</p>
            <BaseImg
              v-if="showSelectDone && currentValue === getValue(item)"
              class="check-done"
              :src="`images/common/icon-check-done.svg`"
              alt="check done"
            />
          </li>
        </ul>
      </div>
      <div class="base-selector__inner">
        <div v-if="!currentValue" class="base-selector__empty">
          <BaseImg
            v-if="placeholderIcon"
            :src="placeholderIcon"
            alt="icon default"
          />
          <p>{{ placeholder }}</p>
        </div>
        <div v-else class="base-selector__value">
          <BaseImg v-if="iconShow && !firstValue" :src="iconShow" :alt="textShow" />
          <p>{{ firstValue ? 'Sắp xếp' : textShow }}</p>
        </div>
      </div>
    </div>
    <p v-if="$v.currentValue.$invalid && $v.$dirty" class="error">
      {{ errorRequired || `Vui lòng chọn ${label ? label.toLowerCase() : ''}` }}
    </p>
  </div>
</template>

<script setup lang="ts">
import useValidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import BaseImg from '~/components/common/base-img.vue'

const { $device } = useNuxtApp()

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: ''
  },
  name: {
    type: String,
    default: ''
  },
  className: {
    type: String,
    default: ''
  },
  textField: {
    type: String,
    default: ''
  },
  valueField: {
    type: String,
    default: ''
  },
  label: {
    type: String,
    default: ''
  },
  items: {
    type: Array as () => Array<{ [key: string]: any }>,
    default: () => []
  },
  required: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  errorRequired: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    default: ''
  },
  placeholderIcon: {
    type: String,
    default: ''
  },
  getObjectValue: {
    type: Boolean,
    default: false
  },
  firstValue: {
    type: Boolean,
    default: false
  },
  srcIconSelectDown: {
    type: String,
    default: 'assets/images/common/icon-down.svg'
  },
  showSelectDone: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update:modelValue', 'change', 'getItem'])
const isShowOptions = ref(false)

const currentValue = computed({
  get() {
    return props.modelValue
  },
  set(value: any) {
    if (props.getObjectValue) {
      const selectedItem = props.items.find((item) => item[props.valueField] === value)
      emit('change', selectedItem)
    } else {
      emit('change', value)
    }
    emit('update:modelValue', value)
  }
})

const textShow = computed(() => {
  const data = props.items.find((item) => item[props.valueField] === currentValue.value)
  return data ? data[props.textField] : currentValue.value
})

const iconShow = computed(() => {
  if (!props.textField || !props.valueField) {
    return ''
  }
  const selectedItem = props.items.find((item) => item[props.valueField] === currentValue.value)
  return selectedItem ? ($device.isDesktop ? selectedItem.icon : selectedItem.iconMB) : ''
})

const rules = computed(() => ({
  currentValue: props.required ? required : true
}))

const $v = useValidate(rules, currentValue)

const validate = () => {
  $v.value.$touch()
  return !$v.value.$invalid ? props.modelValue : undefined
}

const resetValidate = () => {
  $v.value.$reset()
}

const getValue = (item: any) => props.valueField ? item[props.valueField] : item
const getText = (item: any) => props.textField ? item[props.textField] : item

const chooseOption = (item: any) => {
  currentValue.value = props.valueField ? item[props.valueField] : item
  isShowOptions.value = false
  emit('getItem', item)
}

const hideOption = () => {
  isShowOptions.value = false
}

const toggleOptions = () => {
  isShowOptions.value = !isShowOptions.value
}

const getIconSrc = (item: any) => $device.isMobileOrTablet ? item.iconMB || item.icon : item.icon

defineExpose({
  validate,
  resetValidate
})
</script>

<style lang="scss" scoped src="public/assets/scss/components/common/base-selector.scss" />
