<template>
  <div
    class="banner-item"
    @click="handleClick()"
  >
    <BaseImg :src="item.img" :alt="item?.name" lazy />
  </div>
</template>

<script setup lang="ts">

import BaseImg from '~/components/common/base-img.vue'

interface BannerItem {
  img: string;
  name?: string;
}

defineProps<{ item: BannerItem }>()

const emit = defineEmits(['click'])

const handleClick = () => {
  emit('click')
}
</script>

<style lang="scss" scoped src="public/assets/scss/components/desktop/pages/home/banner/banner-item.scss" />
