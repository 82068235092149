<template>
  <div class="floating">
    <div v-for="(item, indexSubMenu) in store.contact" :key="indexSubMenu" class="floating__item">
      <NuxtLink :to="item.url" target="_blank" class="floating__item--image">
        <BaseImg v-if="!isBotChromeLighthouse" class="icon icon-bottom" :src="item.img" alt="icon" />
        <div class="tooltip">
          <div class="tooltip-content">
            <span class="description">{{ item?.display_name }}</span>
          </div>
          <div class="arrow-down" />
        </div>
      </NuxtLink>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useAppStore } from '~/store/app'
import { useContactStore } from '~/store/contact'
const { $pinia } = useNuxtApp()
const { isBotChromeLighthouse } = useAppStore()
const store = useContactStore($pinia)

</script>
<style lang="scss" scoped src="public/assets/scss/components/desktop/floating-menu/index.scss" />
