import { LOBBY_GAME_URLS, PAGE_URLS } from '~/config/page-url'

export const gameCenters = [
  {
    label: 'sport',
    url: PAGE_URLS.SPORT,
    text: 'Thể Thao',
    icon: '/assets/images/components/desktop/pages/home/games/thumb-the-thao.webp'
  },
  {
    label: 'livecasino',
    url: PAGE_URLS.CASINO,
    text: 'Live casino',
    icon: '/assets/images/components/desktop/pages/home/games/thumb-livecasino.webp'
  },
  {
    label: 'table-game',
    url: LOBBY_GAME_URLS.TABLE_GAME,
    text: 'Table Games',
    icon: '/assets/images/components/desktop/pages/home/games/thumb-table-game.webp',
    jackpot: 'jackpotIngame'
  },
  {
    label: 'quay-so',
    url: LOBBY_GAME_URLS.QUAY_SO,
    text: 'Quay Số',
    icon: '/assets/images/components/desktop/pages/home/games/thumb-quay-so.webp'
  },
  {
    label: 'game-nhanh',
    url: LOBBY_GAME_URLS.QUICK_GAMES,
    text: 'Game Nhanh',
    icon: '/assets/images/components/desktop/pages/home/games/thumb-game-nhanh.webp'
  },
  {
    label: 'no-hu',
    url: LOBBY_GAME_URLS.NO_HU,
    text: 'Nổ Hũ',
    jackpot: 'jackpotNohu',
    icon: '/assets/images/components/desktop/pages/home/games/thumb-no-hu.webp'
  },
  {
    label: 'game-bai',
    url: LOBBY_GAME_URLS.GAME_BAI,
    text: 'Game Bài',
    icon: '/assets/images/components/desktop/pages/home/games/thumb-game-bai.webp'
  },
  {
    label: 'slots',
    url: LOBBY_GAME_URLS.SLOTS_GAME,
    text: 'Slots',
    icon: '/assets/images/components/desktop/pages/home/games/thumb-slots.webp'
  },
  {
    label: 'ban-ca',
    url: LOBBY_GAME_URLS.BAN_CA,
    text: 'Bắn Cá',
    jackpot: 'jackpotFishing',
    icon: '/assets/images/components/desktop/pages/home/games/thumb-ban-ca.webp'
  },
  {
    label: 'lo-de',
    url: LOBBY_GAME_URLS.LO_DE,
    text: 'Lô Đề',
    icon: '/assets/images/components/desktop/pages/home/games/thumb-lo-de.webp'
  }
]
