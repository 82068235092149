<template>
  <div class="account-info">
    <div class="account-info__header">
      <div class="header-left">
        <div class="user-avatar">
          <BaseImg class="avatar" alt="user avatar" :src="`${PATH_AVATAR_URL}${store.currentUser.avatar}.webp`" />
        </div>
        <div class="user-info">
          <div class="user-name">{{ store.currentUser.fullname }}</div>
          <div class="user-info-rank">
            <BaseImg class="rank" alt="rank" :src="`${PATH_RANK_URL}vip-${ranks >= 1 ? ranks + 1 : 1}.webp`" />
            <span class="vip">
              {{ 'Vip ' + (ranks >= 1 ? ranks + 1 : 1) }}
            </span>
          </div>
        </div>
      </div>
      <div class="header-right" @click="toggleMenuAccount">
        <div class="icon-close" />
      </div>
    </div>
    <div class="account-info__content">
      <div class="account-info__row">
        <div class="text">{{ USER_INFO.WALLET.text }}</div>
        <div class="value">{{ $formatNumberWithCommas(store.currentUser.balance, ',') }} K</div>
      </div>
      <div v-if="store.currentUser?.package_id && store.currentUser?.package_id !== 1" class="account-info__row">
        <div class="text">
          {{ USER_INFO.ROLLING.text }}
          <BaseTooltip :icon-url="'assets/images/components/desktop/pages/account/profile/tooltip.svg'">
            <div class="note">{{ USER_INFO.ROLLING.note }}</div>
            <div class="arrow-down" />
          </BaseTooltip>
        </div>
        <div class="value">
          {{ $formatAmountUnit(Math.max(accountStore.plan?.rolling - accountStore.plan?.turnover, 0)) }} K
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useAppStore } from '~/store/app'
import { USER_INFO } from '~/constants/account/layout/index.ts'
import { PATH_AVATAR_URL, PATH_RANK_URL } from '~/constants/path'
import { useToggleMenu } from '~/composables/useToggleMenu'
import BaseImg from '~/components/common/base-img'
import { useAccountStore } from '~~/store/account'
import { useInitData } from '~~/composables/user/useInitData'
import BaseTooltip from '~/components/common/base-tooltip.vue'

const { $formatAmountUnit, $formatNumberWithCommas } = useNuxtApp()
const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const accountStore = useAccountStore($pinia)
const { toggleMenuAccount } = useToggleMenu()
const { fetchUserRank, fetchPlan } = useInitData()

const ranks = computed(() => {
  return store.currentUserRank
})

onMounted(() => {
  fetchUserRank()
  fetchPlan()
})
</script>
<style lang="scss" scoped src="public/assets/scss/components/mobile/common/page-account/user-info.scss" />
