import { PAGE } from '~/constants/router'

export const HOME_PROMOTION = [
  {
    id: '1',
    image: '/assets/images/components/desktop/home/promotion/thumb-promotion1.webp',
    link: PAGE.PROMOTION + '/thuong-chao-mung-100percent',
    title: '100%',
    text1: 'Thưởng nạp lần đầu',
    text2: 'Dành cho thành viên mới'
  },
  {
    id: '2',
    image: '/assets/images/components/desktop/home/promotion/thumb-promotion2.webp',
    link: PAGE.PROMOTION + '/thuong-chao-mung-300',
    title: '300%',
    text1: 'Tăng X3 Vốn',
    text2: 'Chinh Phục Mọi Kèo'
  },
  {
    id: '3',
    image: '/assets/images/components/desktop/home/promotion/thumb-promotion3.webp',
    link: PAGE.PROMOTION + '/thuong-chao-mung-150',
    title: '150%',
    text1: 'Khuyến mãi nạp 150%',
    text2: 'Cho Tân Bet Thủ'
  },
  {
    id: '4',
    image: '/assets/images/components/desktop/home/promotion/thumb-promotion4.webp',
    link: PAGE.PROMOTION + '/hoan-tra-live-casino',
    title: '0.2%',
    text1: 'Hoàn Trả Live Casino',
    text2: 'Vô Hạn'
  }
]

export const TYPE_NAVIGATE_LINK = 'navigate-link'
export const PLAN_TYPE_SUKIEN_THUONG18 = 3
export const PLAN_TYPE_SUKIEN_HOANTRA15 = 1
export const PLAN_TYPE_SUKIEN_THUONG118 = 2
export const PLAN_TYPE_SUKIEN_THUONG150 = 5
export const PLAN_TYPE_SUKIEN_THUONG100 = 100
