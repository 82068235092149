<template>
  <div id="header" class="header">
    <div class="header__container container-custom">
      <div class="header-menu">
        <nuxt-link id="logo" class="logo" :to="PAGE_URLS.HOME">
          <BaseImg :src="LOGO_SITE_IMAGE" alt="logo" />
        </nuxt-link>
        <div class="menu">
          <div
            v-for="(item, index) in menus"
            :key="index"
            class="menu-item"
            :class="{
              openSubMenu: openSubMenu === item?.id
            }"
            @mouseover="onMouseOver(item)"
            @mouseleave="onMouseLeave()"
          >
            <NuxtLink
              :class="{ active: checkMenuActive(item) }"
              class="menu-item__content"
              :to="item.route"
              @click="onClickMenu()"
            >
              <BaseImg :src="item.icon" alt="icon" />
              <p class="title">{{ item.title }}</p>
            </NuxtLink>
            <div v-if="item.subMenu && item.subMenu.length > 0" class="sub-menu">
              <div class="sub-menu__list">
                <div
                  v-for="(subItem, index) in item.subMenu"
                  :id="`item-${index}`"
                  :key="index"
                  class="sub-menu__item"
                  :class="[
                    subItem.label,
                    { sub_active: isActiveSubMenu(subItem, item) },
                    { 'sub-menu__sport': item.id === 'sport' }
                  ]"
                  :style="{ background: subItem.background || BACKGROUND_DEFAULT }"
                  @click="getLinkSubMenu(item, subItem)"
                >
                  <BaseImg :src="subItem.image" alt="icon" />
                  <span v-if="item.type === 'casino' || item.type === 'game'" class="sub-menu__item_title">
                    {{ subItem.title }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="header-button">
        <client-only>
          <UserNotLogin v-if="!currentUser" />
          <UserLogged v-else />
        </client-only>
        <div id="notification-header" class="header-button__notif">
          <BaseImg
            id="bell"
            class="bell"
            :class="{ ring: isRing }"
            src="/assets/images/components/desktop/icon-noti.svg"
            alt="bell"
          />
          <div v-if="lengthObjectsNotify?.length" class="numbers">
            {{ lengthObjectsNotify?.length > 99 ? '99+' : lengthObjectsNotify?.length }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { LOGO_SITE_IMAGE } from '~~/constants/menu'
import BaseImg from '~/components/common/base-img.vue'
import { useAppStore } from '@/store/app'
import UserNotLogin from '~/components/desktop/header/user-not-login.vue'
import UserLogged from '~/components/desktop/header/user-logged.vue'
import { IMenuHeader, ISubMenuHeader } from '~/types/menu.type'
import { useGameStore } from '~~/store/game'
import { LOBBY_GAME_URLS, LOBBY_CASINO_URLS, PAGE_URLS } from '~/config/page-url'
import { IProvider } from '~/types/lobby.type'
import { PATH_ICON } from '~/constants/menu'
import { useGame } from '~/composables/game/useGame'
import { useCasino } from '~/composables/casino/useCasino'
import { getHeaderMenu, HEADER_BACKGROUND_CASINO, HEADER_BACKGROUND_GAME } from '~/constants/header'
import { MODAL_TYPES } from '~/config/constant'

const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { isLogged, currentUser } = storeToRefs(store)
const storeGame = useGameStore($pinia)
const { providerGames, providerCasino } = storeToRefs(storeGame)
const router = useRouter()
const { fetchCongGame } = useGame()
const { fetchGameCasino } = useCasino()
const { fetchListBank } = useListBank()
const route = useRoute()
const menuActive = ref('home')
const isRing = ref(false)
const openSubMenu = ref('')
const { HEADER_MENU } = getHeaderMenu()
const BACKGROUND_DEFAULT = 'linear-gradient(180deg, #59AD31 0%, #FFF 100%)'
const { openModalWithNavigate } = useModal()
let interval

const isActiveSubMenu = (subItem: ISubMenuHeader, item: IMenuHeader) => {
  if (item.type === 'sport') {
    return route.fullPath === subItem.url
  }
  return (
    subItem.alias === route.params.type ||
    subItem.alias === route.params.id ||
    (subItem.alias === 'all' && route.path === '/livecasino')
  )
}

const onMouseOver = (item: IMenuHeader) => {
  if (item.subMenu && item.subMenu.length) {
    if (item?.id != null) {
      openSubMenu.value = item.id
    }
  }
}
const onMouseLeave = () => {
  openSubMenu.value = ''
}

const menus = computed(() =>
  HEADER_MENU.map((item) => {
    if (item.id === 'games') {
      return {
        ...item,
        subMenu: providerGames.value.map((subItem) => ({
          title: subItem.display_name,
          image: subItem.icon,
          url: LOBBY_GAME_URLS[subItem.alias],
          alias: subItem.alias,
          background: HEADER_BACKGROUND_GAME[subItem.alias]
        }))
      }
    }
    if (item.id === 'live-casino') {
      return {
        ...item,
        subMenu: providerCasino.value.map((subItem) => ({
          title: subItem.display_name,
          image: subItem.icon,
          url: LOBBY_CASINO_URLS[subItem.alias],
          alias: subItem.alias,
          background: HEADER_BACKGROUND_CASINO[subItem.alias]
        })).filter((item) => item.alias !== 'all')
      }
    }
    return item
  })
)

const fetchData = async () => {
  const promises = []
  if (providerGames.value?.length === 0) {
    promises.push(fetchCongGame())
  }
  if (providerCasino.value?.length === 0) {
    promises.push(fetchGameCasino())
  }

  await Promise.allSettled(promises)
}
const onClickMenu = () => {
  openSubMenu.value = ''
}
const getLinkSubMenu = (item: IMenuHeader, subItem: ISubMenuHeader) => {
  onClickMenu()
  if (item.type !== 'sport') {
    let url = `${item.link}/${subItem.alias}`
    if (subItem.alias === 'all') {
      url = item.link
    }
    router.push(url)
  } else if (subItem.isRequiredLogin) {
    openPage(subItem.url)
  } else {
    router.push(subItem.url)
  }
}

const openPage = (url: string) => {
  if (isLogged.value) {
    router.push(url)
  } else {
    navigateTo({
      query: { ...route.query, popup: MODAL_TYPES.LOGIN }
    })
    openModalWithNavigate(MODAL_TYPES.LOGIN, 'login', url)
  }
}

watch(isLogged, () => {
  fetchListBank()
})

watch(isLogged, (newVal) => {
  if (newVal) {
    setTimeout(() => {
      const openUrl = route.query.openUrl as string
      if (openUrl) {
        router.push({ path: openUrl })
      }
    }, 700)
  }
})

const ringBell = () => {
  interval = setInterval(() => {
    isRing.value = !isRing.value
  }, 5000)
}

onMounted(async () => {
  ringBell()
  fetchData()
  await fetchListBank()
})

const checkMenuActive = (item: any) => {
  return route.fullPath === '/game/da-ga' ? route.fullPath === item.link : route.fullPath.includes(item.link)
}
const listLink = reactive(HEADER_MENU)

watchEffect(() => {
  const updateSubMenu = (submenu: ISubMenuHeader[], type: string) => {
    const targetItem = listLink.find((item) => item.type === type)
    if (targetItem && submenu?.length) {
      const updatedSubmenu = submenu
        .map((subItem: ISubMenuHeader) => ({
          ...subItem,
          icon: {
            inactive: `${PATH_ICON}icon-${subItem.alias}.svg`,
            active: `${PATH_ICON}icon-${subItem.alias}-active.svg`
          }
        }))
        .filter((item) => item.alias !== 'da-ga')
      targetItem.subMenu = isLogged.value
        ? updatedSubmenu
        : updatedSubmenu.filter((item: ISubMenuHeader) => item?.alias !== 'recently')
    }
  }

  const subMenusToUpdate = [
    { submenu: providerGames.value, type: 'games' },
    { submenu: providerCasino.value, type: 'livecasino' }
  ]
  subMenusToUpdate.forEach(({ submenu, type }) => updateSubMenu(submenu, type))
})
</script>

<style scoped lang="scss" src="public/assets/scss/components/desktop/header/index.scss"></style>
