<template>
  <footer id="footer" class="section-footer">
    <div class="container-custom footer">
      <div class="row-center">
        <nuxt-link to="/" class="logo">
          <BaseImg :src="LOGO_SITE_IMAGE" alt="logo" @click="goHome" />
        </nuxt-link>
        <div class="slogan_wrapper">
          <div class="seo-content__title" v-html="dataSeo?.content_title" />
          <div class="seo-content__description" v-html="dataSeo?.content_description" />
        </div>
      </div>
      <div class="footer-navigation">
        <div v-for="item in NAVIGATIONS" :key="item.title" class="footer-section">
          <h4 class="section-title">
            {{ item.title }}
          </h4>
          <div>
            <div class="row-footer">
              <div v-for="(pair, index) in chunk(item.subCategories, 2)" :key="index" class="column">
                <nuxt-link
                  v-for="category in pair"
                  :id="category.title"
                  :key="category.title"
                  class="category-name"
                  :to="category.link"
                >
                  {{ category.title }}
                </nuxt-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row-center footer-deposit">
        <a
          v-for="item in footerPaymentMethods"
          :id="item.title"
          :key="item.title"
          :href="item?.link || ''"
          @click.prevent="handleClickMethod(item, $event)"
        >
          <div v-if="!isLogged" class="deposit-item">
            <BaseImg class="deposit-image" :src="item.image" :alt="item?.title" />
            <p class="deposit-title">{{ item.title }}</p>
          </div>
          <div v-else-if="isLogged && !item.isMaintain" class="deposit-item">
            <BaseImg class="deposit-image" :src="item.image" :alt="item.title" />
            <p class="deposit-title">{{ item.title }}</p>
          </div>
          <div v-else class="deposit-item" :class="{ maintain: item.isMaintain }">
            <BaseImg class="deposit-image" :src="item.image" :alt="item.title" />
            <p class="deposit-title">{{ item.title }}</p>
            <div v-if="item.isShowTooltip" class="tooltip">
              <div class="tooltip-content">
                <span class="description">Đang bảo trì</span>
              </div>
              <div class="arrow-down" />
            </div>
          </div>
        </a>
      </div>
    </div>
    <div class="footer-reserve">
      <div class="container-custom">
        <div class="footer-reserve__content">
          <div class="footer-reserve__left">
            <nuxt-link
              title="DMCA.com Protection Status"
              target="_blank"
              to="//www.dmca.com/Protection/Status.aspx?ID=f362511c-0d21-4b76-af37-f633d9a377ba"
            >
              <BaseImg src="assets/images/components/desktop/footer/dmca.svg" class="dmca" alt="copy right" />
            </nuxt-link>
            <p class="reserve-text">{{ FOOTER_CONTENT.COPY_RIGHT }}</p>
          </div>

          <BaseImg
            src="assets/images/components/desktop/footer/copyright-logo.png"
            class="reserve-image"
            alt="copy right"
          />
        </div>
      </div>
    </div>
  </footer>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import useModal from '~/composables/useModal'
import { useAppStore } from '~~/store/app'
import { PAGE_URLS } from '~/config/page-url'
import { DEPOSIT_LIST, footerContentData } from '@/constants/footer'
import BaseImg from '~/components/common/base-img'
import { LOGO_SITE_IMAGE } from '~~/constants/menu'
import { MODAL_TYPES } from '~/config/constant'
import { useFooterNavigation } from '~/composables/useFooter'
import { useContactStore } from '~/store/contact'
import useBase from '~/composables/useBase'
import { useAccountStore } from '~/store/account'

const { openModalWithNavigate } = useModal()
const router = useRouter()
const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { currentUser, seoData, isLogged } = storeToRefs(store)
const accountStore: any = useAccountStore()
const { NAVIGATIONS } = useFooterNavigation()
const FOOTER_CONTENT = footerContentData()
const { fetchContact } = useContactStore($pinia)

interface FooterPaymentMethod {
  key: string
  title: string
  image: string
  link: string
  isMaintain: boolean
  isShowTooltip: boolean
}

const route = useRoute()
useBase()
const dataSeo = computed(() => {
  if (route.path === '/') {
    return seoData.value.find((item) => item.alias === '/')
  }
  if (route.name === 'khuyen-mai-id') {
    return seoData.value.find((item) => item.alias === 'khuyen-mai')
  }
  return (
    seoData.value.find((item) => item.alias === route.path.slice(1)) ?? seoData.value.find((item) => item.alias === '/')
  )
})

const chunk = (array, size) => {
  const chunkedArr = []
  for (let i = 0; i < array.length; i += size) {
    chunkedArr.push(array.slice(i, i + size))
  }
  return chunkedArr
}

const footerPaymentMethods = ref<FooterPaymentMethod[]>([])
const updateFooterPaymentMethods = () => {
  footerPaymentMethods.value = DEPOSIT_LIST.map((item) => {
    let isMaintain = false
    if (!accountStore.loading) {
      switch (item.key) {
        case 'cardList':
          isMaintain = !(
            accountStore.cardList?.VIETTEL?.status === 1 ||
            accountStore.cardList?.MOBIFONE?.status === 1 ||
            accountStore.cardList?.VINAPHONE?.status === 1 ||
            accountStore.cardList?.ZING?.status === 1
          )
          break
        case 'ViettelPays':
          isMaintain = accountStore.viettelPays?.length === 0
          break
        case 'nicepayBanks':
          isMaintain = accountStore.nicepayBanks?.length === 0
          break
        case 'crypto_v2':
          isMaintain = accountStore.crypto_v2?.[item.key]?.length === 0
          break
        case 'momos':
          isMaintain = !accountStore.momos || accountStore.momos.length === 0
          break
        default:
          break
      }
    }
    return {
      ...item,
      isMaintain
    }
  })
}
watch(
  () => [
    accountStore.cardList,
    accountStore.viettelPays,
    accountStore.providerGoPay,
    accountStore.crypto_v2,
    accountStore.momos,
    accountStore.loading
  ],
  () => {
    updateFooterPaymentMethods()
  },
  { deep: true }
)
onMounted(() => {
  updateFooterPaymentMethods()
})

const handleClickMethod = (item: any, event: any) => {
  if (currentUser.value === null) {
    openModalWithNavigate(MODAL_TYPES.LOGIN, 'login', item?.link)
  } else if (!item.isMaintain) {
    navigateTo(item?.link)
  } else {
    item.isShowTooltip = true
    setTimeout(() => {
      item.isShowTooltip = false
    }, 2000)
    event.preventDefault()
  }
}

const openLink = (url: string) => {
  switch (url) {
    case 'LIVECHAT':
      // TODO
      break
    case 'TELEGRAM_CSKH':
      window.open(useRuntimeConfig().TELEGRAM_CSKH, '_blank')
      break
  }
}

const goHome = () => {
  router.push({ path: PAGE_URLS.HOME })
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}
useAsyncData(() => fetchContact())
</script>
<style lang="scss" scoped src="public/assets/scss/components/desktop/footer/index.scss" />
