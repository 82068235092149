<template>
  <div class="game-item" :class="game?.label">
    <NuxtLink :to="game.url" class="item">
      <h3 class="text">{{ game.text }}</h3>
      <BaseImg class="icon-game" :src="game.icon" lazy />
    </NuxtLink>
    <div v-if="sumJackpot[game.jackpot] > 0" class="jackpot">
      <AnimateCountUp :number="sumJackpot[game.jackpot]" :show-coin="false" />
    </div>
    <BaseImg v-if="game.tag" class="tag" :src="game?.tag" alt="tag" />
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useAppStore } from '@/store/app'
import AnimateCountUp from '~/components/common/animate-count-up.vue'

const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { sumJackpot } = storeToRefs(store)

const props = defineProps({
  game: {
    type: Object,
    default: () => {}
  },
  index: {
    type: Number,
    default: 0
  }
})
</script>
<style lang="scss" scoped src="~/public/assets/scss/components/desktop/pages/home/game-center/item.scss"></style>
