<template>
  <BaseImg
    v-show="isShowScroll"
    ref="line"
    class="icon-to-top"
    width="58"
    src="/assets/images/components/desktop/ontotop.webp"
    alt="icon scroll"
    @click="scrollToTop()"
  />
</template>

<script setup lang="ts">

const isShowScroll = ref(false)
const userScroll = () => {
  if (window.scrollY > 0) {
    isShowScroll.value = true
  } else {
    isShowScroll.value = false
  }
}

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  })
}

onMounted(() => {
  window.addEventListener('scroll', userScroll)
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', userScroll)
})
</script>
<style lang="scss" scoped src="public/assets/scss/components/desktop/icon-scroll-top/index.scss" />
