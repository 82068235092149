export type TLIVE_CASINO = {
  key: string
  title: string
  link: string
  loginRequired: boolean
  newTab: boolean
  keyGame: string
  partner: string
  partner_game_id: string
  deny_info: string
  image: string
}

export const HOME_LIVE_CASINO: TLIVE_CASINO[] = [
  {
    key: 'rikvip',
    title: 'Rikvip',
    link: '/livecasino?partner=rik',
    loginRequired: true,
    newTab: true,
    keyGame: 'live-casino',
    partner: 'vivo-sexy',
    partner_game_id: 'baccarat',
    deny_info: 'WELCOME',
    image: '/assets/images/components/desktop/home/live-casino/img-rikvip.webp'
  },
  {
    key: 'go88',
    title: 'Go88',
    link: '/livecasino?partner=go',
    loginRequired: true,
    newTab: true,
    keyGame: 'live-casino',
    partner: 'allbet',
    partner_game_id: '',
    deny_info: 'WELCOME',
    image: '/assets/images/components/desktop/home/live-casino/img-hit.webp'
  },
  {
    key: 'evolution',
    title: 'Evolution',
    link: '/livecasino?partner=evo',
    loginRequired: true,
    newTab: true,
    keyGame: 'live-casino',
    partner: 'evo',
    partner_game_id: 'baccarat',
    deny_info: 'WELCOME',
    image: '/assets/images/components/desktop/home/live-casino/img-evo.webp'
  },

  {
    key: 'pragmatic',
    title: 'Pragmatic',
    link: '/livecasino?partner=pragmatic',
    loginRequired: true,
    newTab: true,
    keyGame: 'live-casino',
    partner: 'pragmatic',
    partner_game_id: 'all',
    deny_info: 'WELCOME',
    image: '/assets/images/components/desktop/home/live-casino/img-pragmatic.webp'
  },
  {
    key: 'ezugi',
    title: 'ezugi Live',
    link: '/livecasino?partner=ezugi',
    loginRequired: true,
    newTab: true,
    keyGame: 'live-casino',
    partner: 'mg',
    partner_game_id: 'MPBaccarat',
    deny_info: 'WELCOME',
    image: '/assets/images/components/desktop/home/live-casino/img-ezugi.webp'
  },
  {
    key: 'mg',
    title: 'MG Live',
    link: '/livecasino?partner=mg',
    loginRequired: true,
    newTab: true,
    keyGame: 'live-casino',
    partner: 'mg',
    partner_game_id: 'MPBaccarat',
    deny_info: 'WELCOME',
    image: '/assets/images/components/desktop/home/live-casino/img-mg-live.webp'
  }
]
