import { PAGE_URLS, ACCOUNT_URLS } from '~/config/page-url'
import { LOBBY_GAME_URLS } from '~/config/page-url'
import { useAppStore } from '~~/store/app'
import { IContact } from '~/types/seo'
const PATH_IMAGE = '/assets/images/components/desktop/footer/'

export const getFooterNavigation = () => {
  const { contact } = useAppStore()
  const { $config } = useNuxtApp()
  const result = contact?.reduce((acc, item: IContact) => {
    acc[item.name.toLowerCase()] = { url: item.url, display_name: item.display_name }
    return acc
  }, {} as Record<string, { url: string; display_name: string }>)

  return {
    NAVIGATIONS: [
      {
        title: 'Thể thao',
        subCategories: [
          { title: 'Lịch thi đấu', link: PAGE_URLS.SCHEDULES },
          { title: 'Cược thể thao', link: PAGE_URLS.SPORT }
        ]
      },
      {
        title: 'Trò chơi',
        subCategories: [
          { title: 'Bắn cá', link: LOBBY_GAME_URLS.BAN_CA },
          { title: 'Slots', link: LOBBY_GAME_URLS.SLOTS_GAME },
          { title: 'Nổ Hũ', link: LOBBY_GAME_URLS.NO_HU },
          { title: 'Xổ Số', link: LOBBY_GAME_URLS.LO_DE },
          { title: 'Table game', link: LOBBY_GAME_URLS.TABLE_GAME },
          { title: 'Quay số', link: LOBBY_GAME_URLS.QUAY_SO },
          { title: 'Games nhanh', link: LOBBY_GAME_URLS.QUICK_GAMES },
          { title: 'Game Bài', link: LOBBY_GAME_URLS.GAME_BAI }
        ]
      },
      {
        title: 'Về chúng tôi',
        subCategories: [
          { title: 'Giới thiệu', link: `${PAGE_URLS.INTRODUCTION}` },
          { title: 'Điều khoản - Điều kiện', link: `${PAGE_URLS.POLICY}` },
          { title: 'Bảo mật', link: `${PAGE_URLS.SECURITY}` },
          { title: 'Quy định chung', link: `${PAGE_URLS.RULE}` }
        ]
      },
      {
        title: 'Thông tin',
        subCategories: [
          { title: 'Khuyến mãi / Sự kiện', link: PAGE_URLS.PROMOTION },
          { title: 'Tin tức', link: PAGE_URLS.NEWS }
        ]
      },
      {
        title: 'Hỗ trợ',
        type: 'ho-tro',
        subCategories: [
          {
            title: 'Telegram CSKH',
            type: 'BUTTON',
            icon: '/assets/images/components/desktop/footer/icon-telegram.svg',
            link: result?.telegram?.url,
            openNewTab: true
          },
          {
            title: `Cộng đồng ${$config.BRANCH_NAME}`,
            type: 'BUTTON',
            icon: '/assets/images/components/desktop/footer/icon-group.svg',
            link: result?.telegram?.url,
            openNewTab: true
          }
        ]
      }
    ]
  }
}

export const DEPOSIT_LIST = [
  {
    key: 'nicepayBanks',
    title: 'CodePay',
    image: `${PATH_IMAGE}nicepay.svg`,
    link: ACCOUNT_URLS.DEPOSIT_DA,
    isMaintain: false
  },
  {
    key: 'p2p',
    title: 'P2P',
    image: `${PATH_IMAGE}P2P.svg`,
    link: ACCOUNT_URLS.TRANSACTION_P2P,
    isMaintain: false
  },
  {
    key: 'crypto_v2',
    title: 'Tiền ảo',
    image: `${PATH_IMAGE}crypto.svg`,
    link: ACCOUNT_URLS.DEPOSIT_CRYPTO,
    isMaintain: false
  },
  {
    key: 'momos',
    title: 'Momo',
    image: `${PATH_IMAGE}digital-wallet.svg`,
    link: ACCOUNT_URLS.DEPOSIT_MOMO,
    isMaintain: false
  },
  {
    key: 'ViettelPays',
    title: 'Viettel Money',
    image: `${PATH_IMAGE}Viettelpay.svg`,
    link: ACCOUNT_URLS.DEPOSIT_VIETTEL_PAY,
    isMaintain: false
  },
  {
    key: 'cardList',
    title: 'Thẻ cào',
    image: `${PATH_IMAGE}card.svg`,
    link: ACCOUNT_URLS.DEPOSIT_CARD,
    isMaintain: false
  }
]

export const footerContentData = () => {
  const { $config } = useNuxtApp()
  return {
    SLOGAN: `${$config.BRANCH_NAME} - Nhà cái thể thao trực tuyến, Siêu chợ games đổi thưởng đỉnh cao`,
    DESCRIPTION: `${$config.BRANCH_NAME} là trang cá cược thể thao hấp dẫn, cung cấp đa dạng về sản phẩm trò chơi như Thể Thao, Trò Chơi, Casino Trực Tuyến và thưởng hoàn trả cao nhất trên thị trường`,
    SLOGAN_DESCRIPTION: `${$config.BRANCH_NAME} - Nhà cái đẳng cấp Châu Âu. ${process.env.BRANCH_NAME} đem đến cho khách hàng dịch vụ cá cược trực tuyến hàng đầu với hàng trăm sản phẩm cược hấp dẫn như: Thể Thao, Thể Thao Ảo, Casino, Number Games, Keno, Sòng Bài Số...`,
    VIEW_MORE: 'xem thêm',
    COPY_RIGHT: `Copyright © 2024 Powered By ${$config.BRANCH_NAME} All Rights Reserved.`
  }
}
