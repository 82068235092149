<template>
  <div class="lottery-item">
    <BaseImg :src="item.image" alt="image" />
    <div class="content">
      <p class="content__title" :class="item.type">{{ item.title }}</p>
      <p class="content__description">Lần quay số tiếp theo bắt đầu trong</p>
      <div class="content__time-row">
        <template v-if="item.showCountDown">
          <div v-if="item.type === '3-mien'" class="time">{{ hours }}h</div>
          <p v-if="item.type === '3-mien'" class="separator">:</p>
          <div class="time">{{ minutes }}m</div>
          <p class="separator">:</p>
          <div class="time">{{ seconds }}s</div>
        </template>
      </div>
      <div :id="item.title" class="content__button" @click.prevent="handleClick">Cược Ngay</div>
    </div>
  </div>
</template>

<script setup>
import useNavigationGame from '~/composables/useNavigationGame'
import useLottery from '@/composables/useLottery'

const { navigationCheckLoggedInAndOpenGame } = useNavigationGame()

const props = defineProps({
  item: {
    type: Object,
    default: () => {}
  }
})

const handleClick = () => {
  const gameItem = {
    type: 'game',
    partner_game_id: props.item.partnerGameId,
    partner_provider: props.item.partnerProvider
  }
  navigationCheckLoggedInAndOpenGame(gameItem)
}

const hours = ref('00')
const minutes = ref('00')
const seconds = ref('00')

let countdownInterval

const getTargetTime = () => {
  const now = new Date()
  const targetTime = new Date(now)

  // If item.type is '3-mien', set target time to 18:00 (6 PM) tomorrow
  if (props.item.type === '3-mien') {
    targetTime.setHours(18, 0, 0, 0)

    // If it's already past 18:00 today, set target to 18:00 tomorrow
    if (now > targetTime) {
      targetTime.setDate(targetTime.getDate() + 1)
    }
  }

  // If item.type is '1-phut', set target time to 1 minute from now
  if (props.item.type === '1-phut') {
    targetTime.setMinutes(now.getMinutes() + 1)
    targetTime.setSeconds(0, 0) // Ensure it counts down in minute intervals
  }

  return targetTime
}

const updateCountdown = () => {
  const now = new Date()
  const targetTime = getTargetTime() // Get target time for '3-mien' or '1-phut'

  // Calculate the time difference in milliseconds
  const timeDifference = targetTime - now

  // If time difference is negative (i.e., the target time has passed), reset for '1-phut' type
  if (timeDifference <= 0 && props.item.type === '1-phut') {
    targetTime.setMinutes(now.getMinutes() + 1)
    targetTime.setSeconds(0, 0) // Reset the target time to 1 minute from now
  }

  // Calculate hours, minutes, and seconds
  const h = Math.floor(timeDifference / (1000 * 60 * 60))
  const m = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60))
  let s = Math.floor((timeDifference % (1000 * 60)) / 1000)

  // For '1-phut' type, if seconds is 0, set it to 60
  if (props.item.type === '1-phut' && m === 0 && s === 0) {
    s = 60
  }

  // Update the countdown values
  hours.value = h.toString().padStart(2, '0')
  minutes.value = m.toString().padStart(2, '0')
  seconds.value = s.toString().padStart(2, '0')
}

const startCountdown = () => {
  countdownInterval = setInterval(updateCountdown, 1000) // Update every second
  updateCountdown() // Initial update immediately
}

// Start the countdown when the component is mounted
onMounted(() => {
  startCountdown()
})

// Clean up the interval when the component is destroyed
onBeforeUnmount(() => {
  clearInterval(countdownInterval)
})
</script>

<style lang="scss" scoped src="public/assets/scss/components/mobile/pages/home/lottery/lottery-item.scss"></style>
