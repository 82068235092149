export const PATH_IMG = '/assets/images/components/desktop/home/live-casino/stream/'

export const homeLiveStreams = [
  {
    id: 1483,
    groupId: '360d8af8-5d64-43df-9bd9-fa91ad6f9c60',
    streamName: 'XpjSI-X3Chu',
    backgroudDefault: `${PATH_IMG}default-taixiu-go.webp`,
    name: 'Tài Xỉu',
    deny_info: 'WELCOME',
    api: '/gameUrl?partnerProvider=go&partnerGameId=qs_txgo-101',
    partner_game_id: 'qs_txgo-101',
    partner_provider: 'go',
    jackpotId: 'go_qs_txgo-101',
    type: 'casino'
  },
  {
    id: 3707,
    groupId: '41b93f00-3f85-4008-86e2-8e297e6799aa',
    streamName: 'XpjSI-uWCBa',
    backgroudDefault: `${PATH_IMG}default-baucua-rik.webp`,
    name: 'Bầu Cua',
    deny_info: 'WELCOME',
    api: '/gameUrl?partnerProvider=rik&partnerGameId=vgmn_108',
    partner_game_id: 'vgmn_108',
    partner_provider: 'rik',
    jackpotId: 'rik_vgmn_108',
    type: 'casino'
  },
  {
    id: 3558,
    groupId: '9291199a-50c5-434b-9e97-5aeb670927d1',
    streamName: 'XpjSI-MsM1Y',
    backgroudDefault: `${PATH_IMG}default-xocdia-go.webp`,
    name: 'Xóc Đĩa',
    deny_info: 'WELCOME',
    api: '/gameUrl?partnerProvider=go&partnerGameId=qs_xocdia-102',
    partner_game_id: 'qs_xocdia-102',
    partner_provider: 'go',
    jackpotId: 'go_qs_xocdia-102',
    type: 'casino'
  }
]

export const homeLiveStreamMb = homeLiveStreams[0]
