import { default as bank8oNhWdhxrnMeta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/account/bank.vue?macro=true";
import { default as bonus979h0WCLdjMeta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/account/bonus.vue?macro=true";
import { default as bankpYUd6kZNkVMeta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/account/deposit/bank.vue?macro=true";
import { default as cardy37eC7uWl7Meta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/account/deposit/card.vue?macro=true";
import { default as codepaynYa7UFCJP2Meta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/account/deposit/codepay.vue?macro=true";
import { default as codepay2VSjl4HppZJMeta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/account/deposit/codepay2.vue?macro=true";
import { default as crypto4qdOYrTZrUMeta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/account/deposit/crypto.vue?macro=true";
import { default as ewalletNj3h5LUBZUMeta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/account/deposit/ewallet.vue?macro=true";
import { default as betRKgKfOLY6hMeta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/account/history/bet.vue?macro=true";
import { default as transactionZB6NmmnXYyMeta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/account/history/transaction.vue?macro=true";
import { default as historyURbpbmBLUdMeta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/account/history.vue?macro=true";
import { default as profileMeJHV36DD1Meta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/account/profile.vue?macro=true";
import { default as trading_45p2pSzJ7rcD7qhMeta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/account/trading-p2p.vue?macro=true";
import { default as bank1xD9X4ml7VMeta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/account/withdraw/bank.vue?macro=true";
import { default as cardt3q2HxYC3LMeta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/account/withdraw/card.vue?macro=true";
import { default as crypto9zCihuhEYpMeta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/account/withdraw/crypto.vue?macro=true";
import { default as accountzVdcLIydsRMeta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/account.vue?macro=true";
import { default as base_45buttonTpSsabI1VgMeta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/dev/base-button.vue?macro=true";
import { default as base_45count_45downYB6SEkIbrwMeta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/dev/base-count-down.vue?macro=true";
import { default as base_45dropdown_45radio2K5UUPkUBmMeta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/dev/base-dropdown-radio.vue?macro=true";
import { default as base_45imgg0oO3eDyhtMeta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/dev/base-img.vue?macro=true";
import { default as base_45inputqXBDwACzj5Meta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/dev/base-input.vue?macro=true";
import { default as base_45loading88btAz5ArUMeta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/dev/base-loading.vue?macro=true";
import { default as base_45modalU6XTzmPjHdMeta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/dev/base-modal.vue?macro=true";
import { default as base_45money_45inputqEKCFyUhHYMeta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/dev/base-money-input.vue?macro=true";
import { default as base_45paginationutefv0ePwPMeta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/dev/base-pagination.vue?macro=true";
import { default as base_45select_45bankrtGPT7tcbsMeta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/dev/base-select-bank.vue?macro=true";
import { default as game_45itemo8kG61R9Z7Meta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/dev/game-item.vue?macro=true";
import { default as indexo4HpzdnPHRMeta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/dev/index.vue?macro=true";
import { default as _91type_93AarfHQSpMpMeta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/game/[type].vue?macro=true";
import { default as gameImItiM7EFUMeta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/game.vue?macro=true";
import { default as _91alias_93mSfnn2MDQ8Meta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/gioi-thieu/[alias].vue?macro=true";
import { default as gioi_45thieuFop7h4lvkrMeta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/gioi-thieu.vue?macro=true";
import { default as _91alias_93lsAm2LGNY7Meta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/huong-dan/[alias].vue?macro=true";
import { default as huong_45dancKRrA0ZFxJMeta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/huong-dan.vue?macro=true";
import { default as indexykeM0JhJaKMeta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/index.vue?macro=true";
import { default as _91id_93eLJ1cKDmqTMeta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/khuyen-mai/[id].vue?macro=true";
import { default as indexrhiJxUqwMgMeta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/khuyen-mai/index.vue?macro=true";
import { default as _91id_93Q67px5PHb1Meta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/khuyen-mai/the-loai/[id].vue?macro=true";
import { default as vipD689pQRrL2Meta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/khuyen-mai/vip.vue?macro=true";
import { default as khuyen_45mai55jXSVBCWTMeta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/khuyen-mai.vue?macro=true";
import { default as _91type_93omEVVS1bWqMeta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/livecasino/[type].vue?macro=true";
import { default as livecasinoDPcMNNMwS1Meta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/livecasino.vue?macro=true";
import { default as reset_45passwordJOv2FnWCqCMeta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/reset-password.vue?macro=true";
import { default as _91id_93WU0G01VPnSMeta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/the-thao/[id].vue?macro=true";
import { default as indexGe9OlBKWnYMeta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/the-thao/chi-tiet-tran-dau/index.vue?macro=true";
import { default as indexryFwAd7SYWMeta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/the-thao/index.vue?macro=true";
import { default as indextFMJctnvvdMeta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/the-thao/lich-thi-dau/index.vue?macro=true";
import { default as _91alias_934ucEAOYQeoMeta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/tin-tuc/[alias].vue?macro=true";
import { default as index6ky0q8Mt4sMeta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/tin-tuc/index.vue?macro=true";
import { default as _91Slug_938zsWjGVdXUMeta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/tin-tuc/the-loai/[Slug].vue?macro=true";
import { default as tin_45tucislzXWo63dMeta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/tin-tuc.vue?macro=true";
import { default as verify_45maillJaIywKQ02Meta } from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/verify-mail.vue?macro=true";
export default [
  {
    name: accountzVdcLIydsRMeta?.name ?? "account",
    path: accountzVdcLIydsRMeta?.path ?? "/account",
    children: [
  {
    name: bank8oNhWdhxrnMeta?.name ?? "account-bank",
    path: bank8oNhWdhxrnMeta?.path ?? "bank",
    meta: bank8oNhWdhxrnMeta || {},
    alias: bank8oNhWdhxrnMeta?.alias || [],
    redirect: bank8oNhWdhxrnMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/account/bank.vue").then(m => m.default || m)
  },
  {
    name: bonus979h0WCLdjMeta?.name ?? "account-bonus",
    path: bonus979h0WCLdjMeta?.path ?? "bonus",
    meta: bonus979h0WCLdjMeta || {},
    alias: bonus979h0WCLdjMeta?.alias || [],
    redirect: bonus979h0WCLdjMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/account/bonus.vue").then(m => m.default || m)
  },
  {
    name: bankpYUd6kZNkVMeta?.name ?? "account-deposit-bank",
    path: bankpYUd6kZNkVMeta?.path ?? "deposit/bank",
    meta: bankpYUd6kZNkVMeta || {},
    alias: bankpYUd6kZNkVMeta?.alias || [],
    redirect: bankpYUd6kZNkVMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/account/deposit/bank.vue").then(m => m.default || m)
  },
  {
    name: cardy37eC7uWl7Meta?.name ?? "account-deposit-card",
    path: cardy37eC7uWl7Meta?.path ?? "deposit/card",
    meta: cardy37eC7uWl7Meta || {},
    alias: cardy37eC7uWl7Meta?.alias || [],
    redirect: cardy37eC7uWl7Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/account/deposit/card.vue").then(m => m.default || m)
  },
  {
    name: codepaynYa7UFCJP2Meta?.name ?? "account-deposit-codepay",
    path: codepaynYa7UFCJP2Meta?.path ?? "deposit/codepay",
    meta: codepaynYa7UFCJP2Meta || {},
    alias: codepaynYa7UFCJP2Meta?.alias || [],
    redirect: codepaynYa7UFCJP2Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/account/deposit/codepay.vue").then(m => m.default || m)
  },
  {
    name: codepay2VSjl4HppZJMeta?.name ?? "account-deposit-codepay2",
    path: codepay2VSjl4HppZJMeta?.path ?? "deposit/codepay2",
    meta: codepay2VSjl4HppZJMeta || {},
    alias: codepay2VSjl4HppZJMeta?.alias || [],
    redirect: codepay2VSjl4HppZJMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/account/deposit/codepay2.vue").then(m => m.default || m)
  },
  {
    name: crypto4qdOYrTZrUMeta?.name ?? "account-deposit-crypto",
    path: crypto4qdOYrTZrUMeta?.path ?? "deposit/crypto",
    meta: crypto4qdOYrTZrUMeta || {},
    alias: crypto4qdOYrTZrUMeta?.alias || [],
    redirect: crypto4qdOYrTZrUMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/account/deposit/crypto.vue").then(m => m.default || m)
  },
  {
    name: ewalletNj3h5LUBZUMeta?.name ?? "account-deposit-ewallet",
    path: ewalletNj3h5LUBZUMeta?.path ?? "deposit/ewallet",
    meta: ewalletNj3h5LUBZUMeta || {},
    alias: ewalletNj3h5LUBZUMeta?.alias || [],
    redirect: ewalletNj3h5LUBZUMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/account/deposit/ewallet.vue").then(m => m.default || m)
  },
  {
    name: historyURbpbmBLUdMeta?.name ?? "account-history",
    path: historyURbpbmBLUdMeta?.path ?? "history",
    children: [
  {
    name: betRKgKfOLY6hMeta?.name ?? "account-history-bet",
    path: betRKgKfOLY6hMeta?.path ?? "bet",
    meta: betRKgKfOLY6hMeta || {},
    alias: betRKgKfOLY6hMeta?.alias || [],
    redirect: betRKgKfOLY6hMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/account/history/bet.vue").then(m => m.default || m)
  },
  {
    name: transactionZB6NmmnXYyMeta?.name ?? "account-history-transaction",
    path: transactionZB6NmmnXYyMeta?.path ?? "transaction",
    meta: transactionZB6NmmnXYyMeta || {},
    alias: transactionZB6NmmnXYyMeta?.alias || [],
    redirect: transactionZB6NmmnXYyMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/account/history/transaction.vue").then(m => m.default || m)
  }
],
    meta: historyURbpbmBLUdMeta || {},
    alias: historyURbpbmBLUdMeta?.alias || [],
    redirect: historyURbpbmBLUdMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/account/history.vue").then(m => m.default || m)
  },
  {
    name: profileMeJHV36DD1Meta?.name ?? "account-profile",
    path: profileMeJHV36DD1Meta?.path ?? "profile",
    meta: profileMeJHV36DD1Meta || {},
    alias: profileMeJHV36DD1Meta?.alias || [],
    redirect: profileMeJHV36DD1Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: trading_45p2pSzJ7rcD7qhMeta?.name ?? "account-trading-p2p",
    path: trading_45p2pSzJ7rcD7qhMeta?.path ?? "trading-p2p",
    meta: trading_45p2pSzJ7rcD7qhMeta || {},
    alias: trading_45p2pSzJ7rcD7qhMeta?.alias || [],
    redirect: trading_45p2pSzJ7rcD7qhMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/account/trading-p2p.vue").then(m => m.default || m)
  },
  {
    name: bank1xD9X4ml7VMeta?.name ?? "account-withdraw-bank",
    path: bank1xD9X4ml7VMeta?.path ?? "withdraw/bank",
    meta: bank1xD9X4ml7VMeta || {},
    alias: bank1xD9X4ml7VMeta?.alias || [],
    redirect: bank1xD9X4ml7VMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/account/withdraw/bank.vue").then(m => m.default || m)
  },
  {
    name: cardt3q2HxYC3LMeta?.name ?? "account-withdraw-card",
    path: cardt3q2HxYC3LMeta?.path ?? "withdraw/card",
    meta: cardt3q2HxYC3LMeta || {},
    alias: cardt3q2HxYC3LMeta?.alias || [],
    redirect: cardt3q2HxYC3LMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/account/withdraw/card.vue").then(m => m.default || m)
  },
  {
    name: crypto9zCihuhEYpMeta?.name ?? "account-withdraw-crypto",
    path: crypto9zCihuhEYpMeta?.path ?? "withdraw/crypto",
    meta: crypto9zCihuhEYpMeta || {},
    alias: crypto9zCihuhEYpMeta?.alias || [],
    redirect: crypto9zCihuhEYpMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/account/withdraw/crypto.vue").then(m => m.default || m)
  }
],
    meta: accountzVdcLIydsRMeta || {},
    alias: accountzVdcLIydsRMeta?.alias || [],
    redirect: accountzVdcLIydsRMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/account.vue").then(m => m.default || m)
  },
  {
    name: base_45buttonTpSsabI1VgMeta?.name ?? "dev-base-button",
    path: base_45buttonTpSsabI1VgMeta?.path ?? "/dev/base-button",
    meta: base_45buttonTpSsabI1VgMeta || {},
    alias: base_45buttonTpSsabI1VgMeta?.alias || [],
    redirect: base_45buttonTpSsabI1VgMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/dev/base-button.vue").then(m => m.default || m)
  },
  {
    name: base_45count_45downYB6SEkIbrwMeta?.name ?? "dev-base-count-down",
    path: base_45count_45downYB6SEkIbrwMeta?.path ?? "/dev/base-count-down",
    meta: base_45count_45downYB6SEkIbrwMeta || {},
    alias: base_45count_45downYB6SEkIbrwMeta?.alias || [],
    redirect: base_45count_45downYB6SEkIbrwMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/dev/base-count-down.vue").then(m => m.default || m)
  },
  {
    name: base_45dropdown_45radio2K5UUPkUBmMeta?.name ?? "dev-base-dropdown-radio",
    path: base_45dropdown_45radio2K5UUPkUBmMeta?.path ?? "/dev/base-dropdown-radio",
    meta: base_45dropdown_45radio2K5UUPkUBmMeta || {},
    alias: base_45dropdown_45radio2K5UUPkUBmMeta?.alias || [],
    redirect: base_45dropdown_45radio2K5UUPkUBmMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/dev/base-dropdown-radio.vue").then(m => m.default || m)
  },
  {
    name: base_45imgg0oO3eDyhtMeta?.name ?? "dev-base-img",
    path: base_45imgg0oO3eDyhtMeta?.path ?? "/dev/base-img",
    meta: base_45imgg0oO3eDyhtMeta || {},
    alias: base_45imgg0oO3eDyhtMeta?.alias || [],
    redirect: base_45imgg0oO3eDyhtMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/dev/base-img.vue").then(m => m.default || m)
  },
  {
    name: base_45inputqXBDwACzj5Meta?.name ?? "dev-base-input",
    path: base_45inputqXBDwACzj5Meta?.path ?? "/dev/base-input",
    meta: base_45inputqXBDwACzj5Meta || {},
    alias: base_45inputqXBDwACzj5Meta?.alias || [],
    redirect: base_45inputqXBDwACzj5Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/dev/base-input.vue").then(m => m.default || m)
  },
  {
    name: base_45loading88btAz5ArUMeta?.name ?? "dev-base-loading",
    path: base_45loading88btAz5ArUMeta?.path ?? "/dev/base-loading",
    meta: base_45loading88btAz5ArUMeta || {},
    alias: base_45loading88btAz5ArUMeta?.alias || [],
    redirect: base_45loading88btAz5ArUMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/dev/base-loading.vue").then(m => m.default || m)
  },
  {
    name: base_45modalU6XTzmPjHdMeta?.name ?? "dev-base-modal",
    path: base_45modalU6XTzmPjHdMeta?.path ?? "/dev/base-modal",
    meta: base_45modalU6XTzmPjHdMeta || {},
    alias: base_45modalU6XTzmPjHdMeta?.alias || [],
    redirect: base_45modalU6XTzmPjHdMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/dev/base-modal.vue").then(m => m.default || m)
  },
  {
    name: base_45money_45inputqEKCFyUhHYMeta?.name ?? "dev-base-money-input",
    path: base_45money_45inputqEKCFyUhHYMeta?.path ?? "/dev/base-money-input",
    meta: base_45money_45inputqEKCFyUhHYMeta || {},
    alias: base_45money_45inputqEKCFyUhHYMeta?.alias || [],
    redirect: base_45money_45inputqEKCFyUhHYMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/dev/base-money-input.vue").then(m => m.default || m)
  },
  {
    name: base_45paginationutefv0ePwPMeta?.name ?? "dev-base-pagination",
    path: base_45paginationutefv0ePwPMeta?.path ?? "/dev/base-pagination",
    meta: base_45paginationutefv0ePwPMeta || {},
    alias: base_45paginationutefv0ePwPMeta?.alias || [],
    redirect: base_45paginationutefv0ePwPMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/dev/base-pagination.vue").then(m => m.default || m)
  },
  {
    name: base_45select_45bankrtGPT7tcbsMeta?.name ?? "dev-base-select-bank",
    path: base_45select_45bankrtGPT7tcbsMeta?.path ?? "/dev/base-select-bank",
    meta: base_45select_45bankrtGPT7tcbsMeta || {},
    alias: base_45select_45bankrtGPT7tcbsMeta?.alias || [],
    redirect: base_45select_45bankrtGPT7tcbsMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/dev/base-select-bank.vue").then(m => m.default || m)
  },
  {
    name: game_45itemo8kG61R9Z7Meta?.name ?? "dev-game-item",
    path: game_45itemo8kG61R9Z7Meta?.path ?? "/dev/game-item",
    meta: game_45itemo8kG61R9Z7Meta || {},
    alias: game_45itemo8kG61R9Z7Meta?.alias || [],
    redirect: game_45itemo8kG61R9Z7Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/dev/game-item.vue").then(m => m.default || m)
  },
  {
    name: indexo4HpzdnPHRMeta?.name ?? "dev",
    path: indexo4HpzdnPHRMeta?.path ?? "/dev",
    meta: indexo4HpzdnPHRMeta || {},
    alias: indexo4HpzdnPHRMeta?.alias || [],
    redirect: indexo4HpzdnPHRMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/dev/index.vue").then(m => m.default || m)
  },
  {
    name: gameImItiM7EFUMeta?.name ?? "game",
    path: gameImItiM7EFUMeta?.path ?? "/game",
    children: [
  {
    name: _91type_93AarfHQSpMpMeta?.name ?? "game-type",
    path: _91type_93AarfHQSpMpMeta?.path ?? ":type()",
    meta: _91type_93AarfHQSpMpMeta || {},
    alias: _91type_93AarfHQSpMpMeta?.alias || [],
    redirect: _91type_93AarfHQSpMpMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/game/[type].vue").then(m => m.default || m)
  }
],
    meta: gameImItiM7EFUMeta || {},
    alias: gameImItiM7EFUMeta?.alias || [],
    redirect: gameImItiM7EFUMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/game.vue").then(m => m.default || m)
  },
  {
    name: gioi_45thieuFop7h4lvkrMeta?.name ?? "gioi-thieu",
    path: gioi_45thieuFop7h4lvkrMeta?.path ?? "/gioi-thieu",
    children: [
  {
    name: _91alias_93mSfnn2MDQ8Meta?.name ?? "gioi-thieu-alias",
    path: _91alias_93mSfnn2MDQ8Meta?.path ?? ":alias()",
    meta: _91alias_93mSfnn2MDQ8Meta || {},
    alias: _91alias_93mSfnn2MDQ8Meta?.alias || [],
    redirect: _91alias_93mSfnn2MDQ8Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/gioi-thieu/[alias].vue").then(m => m.default || m)
  }
],
    meta: gioi_45thieuFop7h4lvkrMeta || {},
    alias: gioi_45thieuFop7h4lvkrMeta?.alias || [],
    redirect: gioi_45thieuFop7h4lvkrMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/gioi-thieu.vue").then(m => m.default || m)
  },
  {
    name: huong_45dancKRrA0ZFxJMeta?.name ?? "huong-dan",
    path: huong_45dancKRrA0ZFxJMeta?.path ?? "/huong-dan",
    children: [
  {
    name: _91alias_93lsAm2LGNY7Meta?.name ?? "huong-dan-alias",
    path: _91alias_93lsAm2LGNY7Meta?.path ?? ":alias()",
    meta: _91alias_93lsAm2LGNY7Meta || {},
    alias: _91alias_93lsAm2LGNY7Meta?.alias || [],
    redirect: _91alias_93lsAm2LGNY7Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/huong-dan/[alias].vue").then(m => m.default || m)
  }
],
    meta: huong_45dancKRrA0ZFxJMeta || {},
    alias: huong_45dancKRrA0ZFxJMeta?.alias || [],
    redirect: huong_45dancKRrA0ZFxJMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/huong-dan.vue").then(m => m.default || m)
  },
  {
    name: indexykeM0JhJaKMeta?.name ?? "index",
    path: indexykeM0JhJaKMeta?.path ?? "/",
    meta: indexykeM0JhJaKMeta || {},
    alias: indexykeM0JhJaKMeta?.alias || [],
    redirect: indexykeM0JhJaKMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/index.vue").then(m => m.default || m)
  },
  {
    path: khuyen_45mai55jXSVBCWTMeta?.path ?? "/khuyen-mai",
    children: [
  {
    name: _91id_93eLJ1cKDmqTMeta?.name ?? "khuyen-mai-id",
    path: _91id_93eLJ1cKDmqTMeta?.path ?? ":id()",
    meta: _91id_93eLJ1cKDmqTMeta || {},
    alias: _91id_93eLJ1cKDmqTMeta?.alias || [],
    redirect: _91id_93eLJ1cKDmqTMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/khuyen-mai/[id].vue").then(m => m.default || m)
  },
  {
    name: indexrhiJxUqwMgMeta?.name ?? "khuyen-mai",
    path: indexrhiJxUqwMgMeta?.path ?? "",
    meta: indexrhiJxUqwMgMeta || {},
    alias: indexrhiJxUqwMgMeta?.alias || [],
    redirect: indexrhiJxUqwMgMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/khuyen-mai/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Q67px5PHb1Meta?.name ?? "khuyen-mai-the-loai-id",
    path: _91id_93Q67px5PHb1Meta?.path ?? "the-loai/:id()",
    meta: _91id_93Q67px5PHb1Meta || {},
    alias: _91id_93Q67px5PHb1Meta?.alias || [],
    redirect: _91id_93Q67px5PHb1Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/khuyen-mai/the-loai/[id].vue").then(m => m.default || m)
  },
  {
    name: vipD689pQRrL2Meta?.name ?? "khuyen-mai-vip",
    path: vipD689pQRrL2Meta?.path ?? "vip",
    meta: vipD689pQRrL2Meta || {},
    alias: vipD689pQRrL2Meta?.alias || [],
    redirect: vipD689pQRrL2Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/khuyen-mai/vip.vue").then(m => m.default || m)
  }
],
    name: khuyen_45mai55jXSVBCWTMeta?.name ?? undefined,
    meta: khuyen_45mai55jXSVBCWTMeta || {},
    alias: khuyen_45mai55jXSVBCWTMeta?.alias || [],
    redirect: khuyen_45mai55jXSVBCWTMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/khuyen-mai.vue").then(m => m.default || m)
  },
  {
    name: livecasinoDPcMNNMwS1Meta?.name ?? "livecasino",
    path: livecasinoDPcMNNMwS1Meta?.path ?? "/livecasino",
    children: [
  {
    name: _91type_93omEVVS1bWqMeta?.name ?? "livecasino-type",
    path: _91type_93omEVVS1bWqMeta?.path ?? ":type()",
    meta: _91type_93omEVVS1bWqMeta || {},
    alias: _91type_93omEVVS1bWqMeta?.alias || [],
    redirect: _91type_93omEVVS1bWqMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/livecasino/[type].vue").then(m => m.default || m)
  }
],
    meta: livecasinoDPcMNNMwS1Meta || {},
    alias: livecasinoDPcMNNMwS1Meta?.alias || [],
    redirect: livecasinoDPcMNNMwS1Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/livecasino.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordJOv2FnWCqCMeta?.name ?? "reset-password",
    path: reset_45passwordJOv2FnWCqCMeta?.path ?? "/reset-password",
    meta: reset_45passwordJOv2FnWCqCMeta || {},
    alias: reset_45passwordJOv2FnWCqCMeta?.alias || [],
    redirect: reset_45passwordJOv2FnWCqCMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: _91id_93WU0G01VPnSMeta?.name ?? "the-thao-id",
    path: _91id_93WU0G01VPnSMeta?.path ?? "/the-thao/:id()",
    meta: _91id_93WU0G01VPnSMeta || {},
    alias: _91id_93WU0G01VPnSMeta?.alias || [],
    redirect: _91id_93WU0G01VPnSMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/the-thao/[id].vue").then(m => m.default || m)
  },
  {
    name: indexGe9OlBKWnYMeta?.name ?? "the-thao-chi-tiet-tran-dau",
    path: indexGe9OlBKWnYMeta?.path ?? "/the-thao/chi-tiet-tran-dau",
    meta: indexGe9OlBKWnYMeta || {},
    alias: indexGe9OlBKWnYMeta?.alias || [],
    redirect: indexGe9OlBKWnYMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/the-thao/chi-tiet-tran-dau/index.vue").then(m => m.default || m)
  },
  {
    name: indexryFwAd7SYWMeta?.name ?? "the-thao",
    path: indexryFwAd7SYWMeta?.path ?? "/the-thao",
    meta: indexryFwAd7SYWMeta || {},
    alias: indexryFwAd7SYWMeta?.alias || [],
    redirect: indexryFwAd7SYWMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/the-thao/index.vue").then(m => m.default || m)
  },
  {
    name: indextFMJctnvvdMeta?.name ?? "the-thao-lich-thi-dau",
    path: indextFMJctnvvdMeta?.path ?? "/the-thao/lich-thi-dau",
    meta: indextFMJctnvvdMeta || {},
    alias: indextFMJctnvvdMeta?.alias || [],
    redirect: indextFMJctnvvdMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/the-thao/lich-thi-dau/index.vue").then(m => m.default || m)
  },
  {
    path: tin_45tucislzXWo63dMeta?.path ?? "/tin-tuc",
    children: [
  {
    name: _91alias_934ucEAOYQeoMeta?.name ?? "tin-tuc-alias",
    path: _91alias_934ucEAOYQeoMeta?.path ?? ":alias()",
    meta: _91alias_934ucEAOYQeoMeta || {},
    alias: _91alias_934ucEAOYQeoMeta?.alias || [],
    redirect: _91alias_934ucEAOYQeoMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/tin-tuc/[alias].vue").then(m => m.default || m)
  },
  {
    name: index6ky0q8Mt4sMeta?.name ?? "tin-tuc",
    path: index6ky0q8Mt4sMeta?.path ?? "",
    meta: index6ky0q8Mt4sMeta || {},
    alias: index6ky0q8Mt4sMeta?.alias || [],
    redirect: index6ky0q8Mt4sMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/tin-tuc/index.vue").then(m => m.default || m)
  },
  {
    name: _91Slug_938zsWjGVdXUMeta?.name ?? "tin-tuc-the-loai-Slug",
    path: _91Slug_938zsWjGVdXUMeta?.path ?? "the-loai/:Slug()",
    meta: _91Slug_938zsWjGVdXUMeta || {},
    alias: _91Slug_938zsWjGVdXUMeta?.alias || [],
    redirect: _91Slug_938zsWjGVdXUMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/tin-tuc/the-loai/[Slug].vue").then(m => m.default || m)
  }
],
    name: tin_45tucislzXWo63dMeta?.name ?? undefined,
    meta: tin_45tucislzXWo63dMeta || {},
    alias: tin_45tucislzXWo63dMeta?.alias || [],
    redirect: tin_45tucislzXWo63dMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/tin-tuc.vue").then(m => m.default || m)
  },
  {
    name: verify_45maillJaIywKQ02Meta?.name ?? "verify-mail",
    path: verify_45maillJaIywKQ02Meta?.path ?? "/verify-mail",
    meta: verify_45maillJaIywKQ02Meta || {},
    alias: verify_45maillJaIywKQ02Meta?.alias || [],
    redirect: verify_45maillJaIywKQ02Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/pages/verify-mail.vue").then(m => m.default || m)
  }
]