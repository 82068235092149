<template>
  <div class="lottery-result">
    <div class="lottery-result__header">
      {{ title }}
    </div>
    <div v-if="!lotteryResult?.special" class="lottery-result__empty">Kết quả đang được cập nhật</div>
    <div v-if="lotteryResult?.special" class="lottery-result__table">
      <div class="lottery-result__table__row">
        <div class="label">Giải ĐB</div>
        <div class="number">
          <div class="special">
            {{ sortNumber(lotteryResult['special']) }}
          </div>
        </div>
      </div>
      <template v-for="(item, index) in prizeList" :key="index">
        <div v-if="lotteryResult[item.key] && isShowPrize(item.key)" class="lottery-result__table__row">
          <div class="label">{{ item.text }}</div>
          <div
            class="number"
            :class="{
              'special-prize': lotteryResult[item.key].split('-').length > 6,
              'special-prize-6': lotteryResult[item.key].split('-').length === 6
            }"
          >
            <div v-if="!lotteryResult[item.key].includes('-')" :class="{ prize1: item.key === 'prize1' }">
              {{ sortNumber(lotteryResult[item.key]) }}
            </div>
            <div
              v-for="(numberItem, indexNumber) in lotteryResult[item.key].split('-')"
              v-else
              :key="indexNumber"
              :class="`item item-${indexNumber + 1}`"
            >
              {{ sortNumber(numberItem) }}
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  lotteryResult: {
    type: Object,
    default: () => {}
  },
  isSortNumber: {
    type: Boolean,
    default: false
  },
  city: {
    type: Number,
    default: null
  },
  prizeList: {
    type: Array,
    default: () => []
  }
})

const sortNumber = (number = '') => {
  if (props.isSortNumber) {
    return number.trim().slice(-3)
  }

  return number
}

const isShowPrize = (prizeKey) => {
  if (props.isSortNumber) {
    if (props.city === 1 && prizeKey === 'prize7') {
      return false
    }

    if (prizeKey === 'prize8') {
      return false
    }
  }

  return true
}
</script>

<style lang="scss" scoped src="public/assets/scss/components/desktop/pages/home/lottery/lottery-result.scss"></style>
