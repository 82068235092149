import validate from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45global from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/middleware/redirect.global.ts";
import set_45layout_45global from "/Users/yoshi/Documents/Project/Brand/ps03x1-web/middleware/setLayout.global.ts";
export const globalMiddleware = [
  validate,
  redirect_45global,
  set_45layout_45global
]
export const namedMiddleware = {
  auth: () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/middleware/auth.ts"),
  "page-desktop": () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/middleware/pageDesktop.ts"),
  "page-mobile": () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/middleware/pageMobile.ts"),
  "un-auth": () => import("/Users/yoshi/Documents/Project/Brand/ps03x1-web/middleware/unAuth.ts")
}