import { SPORTS_URLS, PAGE_URLS } from '~/config/page-url'
import { LOBBY_GAME_URLS } from '~/config/page-url'
import { PATH_ICON_MENU_BOTTOM } from '~/constants/menu'
import { PATH_IMAGES_ICON } from '~/constants/path'
import { useContactStore } from '~/store/contact'
import { IContact } from '~~/types/seo'

export const useFooterNavigation = () => {
  const { $config, $pinia } = useNuxtApp()
  const store = useContactStore($pinia)

  const formatContact = computed(() => store.contact?.reduce((acc, item: IContact) => {
    acc[item.name.toLowerCase().replace(/\s/g, '')] = { url: item.url, display_name: item.display_name, icon: item.img }
    return acc
  }, {} as Record<string, { url: string; display_name: string; icon: string | null }>) as any)

  const NAVIGATIONS = computed(() => [
    {
      title: 'Thể thao',
      subCategories: [
        { title: 'Lịch thi đấu', link: PAGE_URLS.SCHEDULES },
        { title: 'Cược thể thao', link: SPORTS_URLS.INDEX }
      ]
    },
    {
      title: 'Danh mục trò chơi',
      subCategories: [
        { title: 'Bắn cá', link: LOBBY_GAME_URLS.BAN_CA },
        { title: 'Slots', link: LOBBY_GAME_URLS.SLOTS_GAME },
        { title: 'Nổ Hũ', link: LOBBY_GAME_URLS.NO_HU },
        { title: 'Xổ Số', link: LOBBY_GAME_URLS.LO_DE },
        { title: 'Table game', link: LOBBY_GAME_URLS.TABLE_GAME },
        { title: 'Quay số', link: LOBBY_GAME_URLS.QUAY_SO },
        { title: 'Games nhanh', link: LOBBY_GAME_URLS.QUICK_GAMES },
        { title: 'Game Bài', link: LOBBY_GAME_URLS.GAME_BAI }
      ]
    },
    {
      title: 'Về chúng tôi',
      subCategories: [
        { title: `Giới thiệu ${$config.public.BRANCH_NAME}`, link: `${PAGE_URLS.INTRODUCTION}` },
        { title: 'Điều khoản - Điều kiện', link: `${PAGE_URLS.POLICY}` },
        { title: 'Bảo mật', link: `${PAGE_URLS.SECURITY}` },
        { title: 'Quy định chung', link: `${PAGE_URLS.RULE}` }
      ]
    },
    {
      title: 'Thông tin',
      subCategories: [
        { title: 'Khuyến mãi / Sự kiện', link: PAGE_URLS.PROMOTION },
        { title: 'Tin tức', link: PAGE_URLS.NEWS }
      ]
    }
  ] as any)

  const supportMenus = computed(() => [
    {
      name: formatContact.value?.telegram?.display_name,
      url: `${formatContact.value?.telegram?.url}`,
      activeUrl: [],
      iconMB: formatContact.value?.telegram?.icon

    },
    {
      name: formatContact.value?.livechat?.display_name,
      url: `${formatContact.value?.livechat?.url}`,
      activeUrl: [],
      isLiveChat: true,
      iconMB: formatContact.value?.livechat?.icon
    },
    {
      name: formatContact.value?.hotline?.display_name,
      url: `${formatContact.value?.hotline?.url}`,
      activeUrl: [],
      iconMB: formatContact.value?.hotline?.icon
    },
    {
      name: formatContact.value?.['cộngđồngnhàcáivk88']?.display_name,
      url: `${formatContact.value?.['cộngđồngnhàcáivk88']?.url}`,
      activeUrl: [],
      icon: formatContact.value?.['cộngđồngnhàcáivk88']?.icon,
      iconMB: formatContact.value?.['cộngđồngnhàcáivk88']?.icon
    }
  ] as any)

  return {
    NAVIGATIONS,
    supportMenus
  }
}
