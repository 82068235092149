<template>
  <div class="section-contact">
    <div class="section-contact__header">
      <h3 class="title">Liên hệ</h3>
    </div>
    <div class="section-contact__list">
      <div v-for="item in contactList" :key="item.name">
        <div v-if="item.isLiveChat" class="support__item--link" @click="openLiveChat()">
          <ContactItem :item="item" />
        </div>
        <NuxtLink v-else :to="item.url || '#'" target="_blank" class="support__item--link">
          <ContactItem :item="item" />
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import ContactItem from './contact-item.vue'
import { useContact } from '~~/composables/useContact'

const { openLiveChat, contactList, fetchContact } = useContact()

useAsyncData(async () => await fetchContact())

</script>

<style lang="scss" scoped src="/public/assets/scss/components/mobile/pages/home/contact/index.scss"></style>
